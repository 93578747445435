.close-relationship-number-edit-modal {
}
.close-relationship-number-edit-modal .tooltip {
  font-size: 0.8em;
  margin: 8px 0 0;
  line-height: 1.3;
}
.close-relationship-number-edit-modal .content div,
.close-relationship-number-edit-modal .content svg {
  margin-bottom: 8px;
}
.close-relationship-number-edit-modal .content .input-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 55px;
}
.close-relationship-number-edit-modal .content .input-row .button-component {
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;
}
.close-relationship-number-edit-modal .content .input-row .input-component {
  border: 1px solid #709cc1;
  border-radius: 8px;
  width: 100%;
  padding-left: 15px;
  line-height: 2.5;
}
.close-relationship-number-edit-modal .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
  justify-content: center;
}
.close-relationship-number-edit-modal .content svg {
  width: 5em;
  height: 5em;
}
