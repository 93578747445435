.container{
  background-color: #f5f5f5;
  min-height:100vh;

  :global{
    .list{
      background-color: #fff;
      height:100vh;
      overflow-y: auto;
    }

    .detailType{
      .type{
        display:inline-block;
        font-size:15px;
        color:#464646;
        margin-right:10px;
        width:165px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }
      .plus{
        display: inline-block;
        width:30px;
        margin-right:10px;
        color:#FF5050;
        text-align: center;
      }
      .reduce{
        display: inline-block;
        width:30px;
        margin-right:10px;
        color:#12C287;
        text-align: center;
      }

      .tag_2{
        background-color: #2FB432;
        color:#fff;
        padding:2px 8px;
        border-radius: 4px;
      }

      .tag_3{
        background-color: #FB3A31;
        color:#fff;
        padding:2px 8px;
        border-radius: 4px;
      }

      .tag_4{
        background-color: #FCC114;
        color:#fff;
        padding:2px 8px;
        border-radius: 4px;
      }
    }
    
  }
}