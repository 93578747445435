.photographic-plate-download {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
}
.photographic-plate-download .card {
  width: 90%;
  align-items: center;
  background-image: url("https://capricornus1984.oss-cn-hangzhou.aliyuncs.com/photo_studio_h5/2.1/small_the_loops.jpg");
  background-position: left -100px;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  height: 4em;
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  border: 1px solid #f3f3f3;
}
.photographic-plate-download-modal-content {
  padding-top: 35px;
}
.photographic-plate-download .pic {
  margin-left: 20px;
  width: 20%;
  margin-right: 10px;
}
.photographic-plate-download .icon {
  font-size: 2.5em;
  color: #709cc1;
}
.photographic-plate-download .detail {
  display: flex;
  flex-grow: 1;
  width: 80%;
  height: 100%;
  font-size: 0.7em;
  flex-direction: column;
  justify-content: center;
  color: rgb(142, 142, 142);
}

.photographic-plate-download .button-row {
  display: flex;
  position: absolute;
  height: 100%;
  width: 90px;
  right: -90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.photographic-plate-download .button-row .button {
  line-height: 2;
  border-radius: 5px;
  margin-top: 5px;
  color: white;
  font-size: 13px;
  padding: 0 8px;
  letter-spacing: 5px;
  background-color: #709cc1;
}
.photographic-plate-download-modal {
}
.photographic-plate-download-modal p {
  font-size: 0.9em;
  line-height: 1.6;
  text-align: center;
}
.photographic-plate-download-modal .button-row {
  margin-top: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
