.close-relationship-number {
  width: 95%;
  display: flex;
  flex-direction: column;
}
.close-relationship-number .button {
  color: #dce6ef;
  font-size: 4em;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.close-relationship-number .avatar-list {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.close-relationship-number .avatar-list .iconfont-svg {
  width: 2.5em;
  margin-left: 10px;
  margin-top: 8px;
  margin-right: 10px;
  height: 2.5em;
}
.close-relationship-number .avatar-list .inline-button {
  font-size: 2.6em;
  color: #99c4ea;
  margin-left: 10px;
  margin-top: 8px;
  margin-right: 10px;
}
.relationship-modal-tooltip {
  width: 100%;
  font-size: 0.8em;
  padding: 10px;
  background-color: #c3c3c3;
  color: white;
  border-radius: 8px;
  margin-bottom: 15px;
}
