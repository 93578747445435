.Content {
    &>.warp_title{
        font-size: 18px;
        padding: 10px 0;
        margin: 0 10px;
        border-bottom: 1px solid #b5b5b5ee;
    }
    &>.box_code{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        &>img{
            width: 135px;
        }
    }
    &>.code_number{
        font-size: 12px;
        text-align: center;
        color: #c5c5c5;
    }
    &>.tipes_text {
        color: #ff953e;
        font-size: 15px;
        text-align: center;
        margin-top: 20rpx;
    }
    &>.info_list{
        margin-top: 50px;
    }
}

.complaint{
    position: fixed;
    bottom: 10%;
    right: 5%;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 10px;
    font-size: 13px;
    color: #12c287;
    border-radius: 50%;
    box-shadow: 3px 4px 8px #c9c9c9;
    background-color: #fff;
}

.instructions {
    font-size: 15px;
    padding: 10px 15px;
}

.text_show {
    text-align: center;
    font-size: 15px;
    margin-top: 15px;
    color: #9f9f9f;
    color: red;
}

.content {
    padding: 10px;

    &>.step_item {
        padding: 0 0 10px 0;
        border-top: 1px solid #d4d4d4;

        &>.btn_code {
            width: 50%;
            background: #26c132;
            text-align: center;
            padding: 10px 0;
            font-size: 20px;
            font-weight: bold;
            border-radius: 25px;
            margin: 41px auto;
            color: #fff;
        }

        &>.images_content {
            width: 95%;
            margin: 0 auto;

            &>img {
                width: 100%;
            }
        }

        &>.title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;

            &>.size {
                width: 25px;
                height: 25px;
                color: #26c132;
                line-height: 21px;
                text-align: center;
                font-size: 13px;
                font-weight: bold;
                border-radius: 50%;
                border: 2px solid #26c132;
                margin-right: 5px;
            }
        }

        &>.btn_code {}
    }

    &>.title {
        font-size: 20px;
        padding-bottom: 10px;
        border-bottom: 2px solid #d4d4d4;
    }

    &>.exchange_code {
        padding-bottom: 10px;

        &>.exchange_code_content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 40px 0;
            color: #5a5a5a;

            &>.left {
                padding: 10px 0 0px 0px;
            }

            &>.right {
                font-size: 30px;
                font-weight: bold;
                padding-left: 10px;
            }
        }

        &>.tipes {
            text-align: center;
            font-size: 15px;
            color: #26c132;
            // font-weight: bold;
        }
    }
}


.remark_text {
    padding: 0 36upx;
}

.complaint_content {
    padding: 30upx;
    width: 100%;

    &>.complaint_title {
        width: 100%;
        font-size: 13px;
        text-align: center;
        margin-bottom: 10px;
    }

    &>.complaint_textarea {
        width: 100%;
        min-height: 500upx;
        border: 1px solid #cacaca;
        border-radius: 8px;
        padding: 10upx 0;
    }
}