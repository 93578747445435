.content {
    padding: 7px;
}

.CardDetails {
    padding-bottom: 40px;
}

.CardDetails_warp {
    &>img {
        width: 100%;
        border-radius: 7px;
    }
}

.CardDetails_title {
    font-weight: bold;
    padding-bottom: 15px;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #b1b1b1
}

.CodeQr {
    margin-top: 15px;
    &>img {
        display: block;
        width: 110px;
        margin: 0 auto;
    }
    &>p {
        margin: 0;
        font-size: 12px;
        text-align: center;
    }
    &>.desc{
        color: #ff8924;
        text-align: center;
        margin: 7px 0;
        font-size: 16px;
        font-weight: bold;
    }
}

.CardDetails_list {
    margin-top: 20px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 100px;
    &>.CardDetails_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0;
        &>.title{
            color: #ff8924;
        }
        &>.card_list{
            width: 270px;
            &>ul {
                margin: 0;
                &>li{
                    list-style: circle;
                    padding: 5px 0;
                }
            }
        }
    }
    &>.CardDetails_desc{
        &>.title{
            color: #ff8924;
        }
        &>.CardDetails_text{
            margin-top: 7px;
        }
    }
}

.btn_form_CardDetails {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
}

.collect_content {
    min-height: 100vh;
    padding: 10px;
    background-color: rgb(236, 236, 236);
    &>.collect_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        overflow: hidden;
        padding: 10px;
        background-color: rgb(255, 255, 255);
        margin-bottom: 15px;
        &>.collect_img{
            width: 25%;
            &>img{
                display: flex;
                width: 100%;
                border-radius: 10px;
            }
        }
        &>.collect_desc{
            position: relative;
            width: 70%;
            &>.collect_title{
                font-size: 18px;
                font-weight: bold;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            &>.collect_desc_text{
                color: rgb(199, 199, 199);
                font-size: 12px;
                margin: 5px 0;
            }
            &>.collect_time{
                color: rgb(199, 199, 199);
                font-size: 12px;
                &>p{
                    margin: 0;
                }
            }
            &>.sub_Form{
                position: absolute;
                display: inline-block;
                bottom: 0;
                right: 0;
                font-size: 12px;
                padding: 3px;
                border-radius: 2px;
                color: sandybrown;
                border: 1px solid sandybrown;
            }
        }
    }
}