.customer-feedback {
  font-size: 0.8em;
  .phone-row {
    position: fixed;
    bottom: 1em;
    border-radius: 3px;
    border: 1px solid #5f5f5f;
    text-align: center;
    width: 50vw;
    margin-left: 25vw;
    padding: 8px;
    color: black;
    .iconfont {
      padding: 0 5px;
    }
  }
  .page-header {
    line-height: 3;
    width: 100%;
    text-align: center;
  }
  .content {
  }
  .arrive-shop-confirm {
    .note {
      font-size: 0.6em;
      padding-left: 25px;
      margin-top: 15px;
    }
  }

  .paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .paper .center {
    text-align: center;
    font-weight: bold;
  }

  .paper .left {
    text-align: left;
    padding-left: 25px;
  }

  .paper .paper-line {
    line-height: 3;
    font-size: 0.8em;
    letter-spacing: 1px;
    width: 95%;
    border-bottom: 1px solid #d0d0d0;
  }

  .paper .radio {
    font-size: 0.7em;
    color: rgb(79, 79, 79);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
  }

  .suggestion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .date-picker-row {
    padding-top: 2em;
    display: flex;
    justify-content: center;
    // align-items: center;
    // padding-right: 2em;
    // padding-left: 0em;
    // line-height: 2;
    span {
      padding: 0 1em;
    }
    .picker-text {
      display: inline-block;
      padding-top: 12px;
    }
    .picker {
      border: 1px solid #5d5d5d;
      border-radius: 8px;
      padding: 5px 13px;
    }
  }

  .suggestion .title {
    width: 100%;
    font-size: 0.7em;
  }

  .textarea-note {
    line-height: 0;
    padding-left: 10%;
    padding-top: 10%;
    font-size: 0.9em;
  }
  .textarea {
    padding: 5px;
    margin-top: 13px;
    border-radius: 5px;
    border: 1px solid #8f8f8f;
    font-size: 0.8em;
    width: 80%;
    margin-left: 10%;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .button-row button {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: 80%;
    min-width: 100px;
  }
}
.calendar {
  .za-calendar__body {
    height: 60vh;
    overflow: auto;
  }
}

.message-title-retr {
  text-align: center;
  font-size: 13px;
  color: rgb(163, 163, 163);
  margin-top: 6px;
}
