.my-preorder-detail {
  background: #eee;
  min-height: 100vh;
  padding-bottom: 100px;
  .clothe {
    margin-top: 10px;
    .clothe-container {
      display: flex;
      flex-direction: row;
      background: white;
      width: 100%;
      padding: 8px;
      justify-content: center;
      flex-wrap: wrap;
      img {
        width: 80vw;
        height: auto;
      }
    }
  }
  .footer-button-row {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    position: fixed;
    bottom: 1em;
    width: 100%;
    justify-content: space-between;
    button {
      font-size: 0.7em;
    }
  }
}
