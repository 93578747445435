.container {
  background-color: #f5f5f5;
  min-height: 100vh;

  :global {
    .clearfix {
      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .ranking-list {
      background-color: #fff;
      margin-bottom: 12px;

      .ranking-item {
        padding: 15px 10px;
        font-size: 18px;

        .ranking {
          float: left;
          width: 40px;
          height: 50px;
          line-height: 50px;
          color: #464646;
          font-weight: 500;
          text-align: center;
        }

        .avatar {
          float: left;
          margin-left: 10px;
          width: 50px;
          height: 50px;
        }

        .name {
          float: left;
          margin-left: 10px;
          color: #464646;
          font-weight: 500;
          height: 50px;
          line-height: 50px;
        }

        .likes {
          float: right;
          padding: 0 10px;

          .likes-count {
            height: 25px;
            line-height: 25px;
            font-weight: 500;
            color: #BDBDBD;
            text-align: center;
          }

          .no-like {
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(../../assets/no-like.png);
            background-repeat: no-repeat;
            background-size: 16px 16px;
          }

          .like {
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(../../assets/like.png);
            background-repeat: no-repeat;
            background-size: 16px 16px;
          }
        }

        .count {
          float: right;
          margin-right: 10px;
          height: 50px;
          line-height: 50px;
          font-weight: 500;
          color: #12C287;
        }

        .not-on {
          color: #BDBDBD;
          height: 50px;
          line-height: 50px;
          float: right;
          padding: 0 10px;
        }
      }
    }

    .executive-detail {
      position: fixed;
      right: 18px;
      bottom: 118px;
      background-color: #fff;
      border: 1px solid #979797;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
      text-align: center;
      font-size: 15px;
      color: #464646;
    }

    .combinatorial-screening {
      background-color: #fff;
      height: 97px;
      width: 100%;
      position: fixed;
      bottom: 0;

      .item-one {
        margin: 15px 15px 0;

        .za-radio-group {
          display: block;
        }

        .za-radio-group--button {
          .za-radio {
            width: 33.33%;
            height: 24px;
            box-sizing: border-box;
            margin: 0;
            border-color: #19C286;
            border-width: 1px 1px 1px 0;
            color: #19C286;

            &.za-radio--checked {
              color: #fff;
            }

            &:first-child {
              border-left-width: 1px;
            }
          }

          .za-radio-group__inner {
            margin: 0;
          }
        }
      }

      .item-two {
        margin: 15px 15px 0;

        .za-radio-group {
          display: block;
        }

        .za-radio-group__inner {
          display: flex;
        }

        .za-radio-group--button {
          .za-radio {
            flex: 1;
            height: 24px;
            box-sizing: border-box;
            margin: 0;
            border-color: #19C286;
            border-width: 1px 1px 1px 0;
            color: #19C286;

            &.za-radio--checked {
              color: #fff;
            }

            &:first-child {
              border-left-width: 1px;
            }
          }

          .za-radio-group__inner {
            margin: 0;
          }
        }
      }
    }
  }
}

.detailModal {
  :global{
    .t-c {
      text-align: center;
    }

    .za-modal__footer {
      padding: 0;
      border-top: 1px solid #e5e5e5;
  
      .sure {
        color: #00bc71
      }

      .confirmBtns {
        display: flex;
        width: 100%;
    
        .btn {
          flex: 1;
          font-size: 15px;
          box-sizing: border-box;
          height: 40px;
          line-height: 40px;
          color: #666;
    
          &:first-child {
            border-right: 1px solid #e5e5e5
          }
    
          &:last-child {
            color: #00bc71;
          }
        }
      }
    }
  }
}