.footer-buttons {
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  button {
    border: none;
    width: 100%;
    font-size: 20px;
    padding: 0;
    border-radius: 0;
  }
  p {
    padding: 0;
    font-size: 0.7em;
    margin: 0;
    line-height: 2;
    width: 100%;
    border-right: 1px #82f5c7 solid;
  }
  button:last-child {
    p {
      border: none;
    }
  }
}
