.employee-preorder-clothe-brief-detail-img{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .img{
    width: 48vw;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top:1vw;
  }
  .img img{
    max-width: 48vw;
  }
}
.employee-preorder-clothe-brief-detail {
  padding-bottom: 65px;
  min-height: 100vh;
  background: #e2e2e2;
  .za-cell__footer {
    max-width: 70%;
  }
  .tag-container {
    justify-content: flex-end;
  }
  .selection_info {
    background: white;
    font-size: 0.7em;
    padding: 5px;
  }
  .standard-image-container {
    background: white;
    padding: 15px;
    .row {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
    }
    .tags {
      display: flex;
      flex-direction: column;
    }
    span {
      padding: 5px 15px;
      font-size: 0.7em;
    }
    img {
      width: 6em;
    }
  }
}
