// 组件
.img_content {
    width: 100%;
    &>*{
        font-size: 0px;
    }
    &>*>img {
        width: 100%;
        // border-radius: 8px;
    }
}

.img_text{
    position: absolute;
    top: 10px;
    right: 5%;
    width: 20px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    min-height: 120vh;
    padding: 15px 7px;
    // padding-bottom: 300px;
    &>div {
      width: 49%;
      &>div {
          margin-bottom: 3px;
      }
    }
}

.Carousel {
    position: fixed;
    width: 90%;
    top: 30%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 50;
    transition: all .6s;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 16;
    background: #000000;
    opacity: .5;
    transition: all .6s;
}

.Search_Content {
    &>.Search_btn{
        position: fixed;
        bottom: 17%;
        right: 0;
        width: 43px;
        height: 43px;
        // padding: 10px;
        line-height: 2;
        z-index: 27;
        line-height: 1;
        border-radius: 50%;
        // border: 1px solid;
        // opacity: .5;
        background: #999999;
        opacity: 1;
        color: #ffffff;
        border: none;
        font-size: 12px;
        &>.search_icon{
            width: 100%;
            height: 100%;
        }
    }
    &>.Number_btn{
        position: fixed;
        bottom: 10%;
        right: 0;
        // line-height: 2;
        z-index: 27;
        border-radius: 50%;
        // border: 1px solid;
        // opacity: .5;
        color: #ffffff;
        background: #f7b912;
        border: none;
        width: 43px;
        height: 43px;
        font-size: 15px;
    }
    &>.Search_res{
        position: fixed;
        width: 100%;
        // height: 30%;
        bottom: -100%;
        padding: 10px;
        padding-bottom: 65px;
        background: #ffffff;
        transition: all .6s;
        z-index: 25;
    }
    &>.action_search {
        // transform: translateY(15%);
        overflow-y: scroll;
        // height: 265px;
        bottom: 0;
        transition: all .6s;
        font-size: 14px;
    }

    & .Search_Color {
        display: flex;
        justify-content: flex-start;
        margin: 10px 0;
        padding: 0 10px;
    }

    & .Search_Style {
        display: flex;
        justify-content: flex-start;
        margin: 10px 0;
        padding: 0 10px;
    }

    & .item_card {
        padding: 5px 10px;
        border-radius: 25px;
        // border: 1px solid #000000;
        margin: 5px 10px;
        border: 1px solid #00bc71;
    }
}

.clear_btn {
    position: fixed;
    bottom: 35vh;
    right: 10px;
    padding: 5px 10px;
    border: none;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    opacity: .5;
}

.icon_search_img {
    width: 20px;
    height: 20px;
}

.action {
    color: #ffffff;
    background: #00d17d;
}

.Table_name {
    width: 50px;
    padding-top: 10px;
}

.Item_Content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 95%;
}

.Img_res {
    position: fixed;
    width: 100%;
    // height: 30%;
    bottom: -60%;
    padding: 10px;
    background: #ffffff;
    transition: all .5s;
    z-index: 20;
    &>.Img_res_list{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        // height: 350px;
        padding: 5px;
        // background: red;
        overflow-x: scroll;
        overflow-y: hidden;
        &>.item_img{
            position: relative;
            margin-bottom: 10px;
            margin-right: 10px;
            // width: 100%;
            &>.item_img_img{
                // width: 100%;
                // width: 218px;
                height: 200px;
                display: block;
                border-radius: 8px;
                overflow: hidden;
            }
        }
    }
    &>.fot_text{
        margin-top: 15px;
        &>span{
            margin-right: 10px;
        }
    }
}

.icon_img{
    color: #ffffff;
    position: absolute;
    top: 1%;
    right: 4%;
    width: 20px;
    height: 20px;
}

.action_Img {
    bottom: 0;
    transition: all .6s;
}


.Selection {
    text-align: center;
    line-height: 5;
}


.wrap{
    position: fixed;
    width: 100%;
    // top: 10%;
    z-index: 30;
    overflow-x: scroll;
    overflow-y: hidden;
}
.wrap>ul{
    display: flex;
   
}
.wrap>ul>li{
    flex: 1;
    height: 80vh;
    margin-right: 15px;
    box-sizing: border-box;
    &>img {
        border-radius: 5px;
        box-shadow: -2px 3px 4px #d6d6d6;;
    }
}

* {
    touch-action: auto;
}

.detaile_img_icon_close {
    position: fixed;
    z-index: 35;
    top: 2%;
    right: 2%;
    // color: #ffffff;
}

.btn_action {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 60px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}