.collect_content {
    min-height:100vh;
    padding: 10px;
    background-color: rgb(236, 236, 236);
    & .collect_item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        overflow: hidden;
        padding: 10px;
        background-color: rgb(255, 255, 255);
        margin-bottom: 15px;
        height: 105px;
        &>.collect_img{
            width: 25%;
            &>img{
                display: flex;
                width: 100%;
                border-radius: 10px;
            }
        }
        &>.collect_desc{
            position: relative;
            width: 70%;
            &>.collect_title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 23px;
                font-size: 18px;
                font-weight: bold;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 10px;
                &>span{
                    // font-size: 27px;
                    color: #16ab17;
                    display: inline-block;
                    margin-left: 5px;
                }
            }
            &>.collect_desc_text{
                height: 26px;
                color: rgb(199, 199, 199);
                font-size: 12px;
                margin: 5px 0;
                overflow:hidden; 
                text-overflow:ellipsis;
                display:-webkit-box; 
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2; 
            }
            &>.collect_time{
                color: rgb(199, 199, 199);
                font-size: 12px;
                &>p{
                    margin: 0;
                }
            }
            &>.sub_Form{
                position: absolute;
                display: inline-block;
                bottom: 0;
                right: 0;
                font-size: 12px;
                padding: 3px;
                border-radius: 2px;
                color: sandybrown;
                border: 1px solid sandybrown;
            }
        }
    }
}

.addMore {
    text-align: center;
    font-size: 15px;
    color: rgb(218, 218, 218);
    margin-top: 5px;
}

.collect_icon_close {
    font-size: 15px;
    &>.collect_icon_num{
        font-size: 23px;
    }
}

.card_item {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 3px 3px 20px #e4e4e4;
    background-color: #ffffff;
    margin: 20px 0;

    &>.card_img {
        width: 30%;
        height: 100%;
    }

    &>.card_info {
        position: relative;
        width: 65%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>.botm_text {
            position: absolute;
            bottom: 0;
            width: 100%;
            font-size: 11px;
        }
    }

    &>.tips_text {
        position: absolute;
        right: 5%;
        top: 40%;
        color: #b1b1b1;
        padding: 5px 15px;
        border: 1px solid #b1b1b1;
        border-radius: 5px;
        transform: rotateZ(-31deg);
    }
}

.count {
    color: #42b983;
    margin-left: 10px;
    display: inline-block;
}