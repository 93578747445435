.close-relationship-number-page {
  display: flex;
  flex-direction: column;
}
.close-relationship-number-page .line .name {
  display: flex;
  align-items: center;
}
.close-relationship-number-page .line {
  display: flex;
  justify-content: space-between;
  line-height: 3;
  padding-left: 30px;
  align-items: center;
  border-bottom: 1px solid #a0a0a0;
  padding-right: 30px;
}
.close-relationship-number-page .line .iconfont-svg {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
