.customer-confirm {
}
.customer-confirm {
  line-height: 3;
  background: white;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
}
.customer-confirm .paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.customer-confirm .paper .center {
  text-align: center;
  font-weight: bold;
}
.customer-confirm .paper .left {
  text-align: left;
  padding-left: 25px;
}
.customer-confirm .paper .paper-line {
  line-height: 3;
  font-size: 0.8em;
  letter-spacing: 1px;
  width: 95%;
  border-bottom: 1px solid #d0d0d0;
}
.customer-confirm .paper .radio {
  font-size: 0.7em;
  color: rgb(79, 79, 79);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.customer-confirm .suggestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.customer-confirm .suggestion .title {
  width: 100%;
  font-size: 0.7em;
}
.customer-confirm .suggestion textarea {
  margin-top: 13px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #6c9bc3;
  min-height: 5em;
  font-size: 0.7em;
  width: 80%;
}

.customer-confirm .button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.customer-confirm .button-row button {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  width: 80%;
  min-width: 100px;
}
