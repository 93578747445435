.comment_tabs{
  background-color: #fff;
  :global{
    .za-tabs__header{
      background-color: #fff;
      position:fixed;
      top:0;
      z-index: 10;
    }

    // .za-pull__body{
    //   padding-top:45px;
    // }
  }
}
.comments_tab_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  :global {
    .tab-title {
      font-size: 13px;
      padding: 0 3px;
    }
  }
}

.comment_list {
  background-color: #fff;
  max-height: calc(100vh - 175px);
  padding-top:45px;
}

.comment_item {
  :global {
    .comment-title {
      color: #464646;
      font-size: 15px;
    }

    .comment-description {
      color: #909090;
      word-break: break-all;
    }
  }
}