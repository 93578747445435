.container{
  background-color: #f5f5f5;
  min-height: 100vh;
  
  :global{
    .tabs{
      background-color: #fff;
      position:fixed;
      top:0;
      width:100%;
      z-index: 10;
      .za-badge{
        margin:0 0 5px 5px;
      }
    }

    .list{
      height:100vh;
      overflow-y: auto;
      box-sizing: border-box;
      padding-top:45px;
    }

    .list_item{
      background-color: #fff;
      line-height:24px;

      .title{
        color:#464646;
        font-size:14px;
      }

      .description{
        color:#909090;
        font-size:12px;
      }
      
      .syllable{
        margin-right:20px;
      }
    }
  }
}
