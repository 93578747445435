.container{
  background-color: #f5f5f5;
  min-height: 100vh;
}
.badge{
  :global{
    .za-badge{
      margin: 0 0 3px 10px;
    }
  }

}