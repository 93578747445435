.headerImage {
  &>img {
    width: 100%;
    display: block;
  }
}

.goodsInDetail {
  padding: 10px;
  color: #fff;
  font-size: 15px;
  line-height: 1.5;
  background-color: rgb(206, 73, 73);
  &>.inventoryInformation{
    font-size: 13px;
  }
}

.theCountdownActivity {
  text-align: center;
  line-height: 2;
  padding: 10px 0;
  background-color: rgb(255, 255, 255);
  &>.theCountdownToTheTitle{
    font-size: 24px;
  }
  &>.theCountdownActivity{
    display: flex;
    justify-content: center;
    align-items: center;
    &>div{
      width: 30px;
      height: 30px;
      color: #fff;
      text-align: center;
      line-height: 30px;
      border-radius: 4px;
      margin: 0 5px;
      background-color: #1c1c1c;
    }
    &>span{
      color: #797979;
      font-size: 13px;
    }
  }
}

.suspendedButtonAtTheBottomOfThe {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(158, 121, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &>div{
    width: 50%;
    text-align: center;
    line-height: 4;
    font-size: 15px;
    color: #fff;
  }
  &>.endOfWords{
    background-color: rgb(209, 63, 63);
  }
}

.theReservationNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4c61b;
  &>div{
    padding: 10px 0;
    font-size: 21px;
    font-weight: bold;
  }
}

.byChildren {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  // height: 200px;
  padding: 10px;
  background-color: #fff;
  &>img {
    width: 55px;
    height: 55px;
    margin: 6px;
    border-radius: 50%;
  }
}

.priceOfTheTitle {
  font-size: 19px;
}

.participation {
  padding: 10px 0;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background-color: #1c1c1c;
}

.getTheInformation > div{
  background-color: #fff1d1;
}

.theRightSideOfTheFixedFunction {
  position: fixed;
  right: 0;
  bottom: 40%;
  width: 75px;
  // height: 130px;
  // background-color: #e4c61b;
  font-size: 13px;
  border-radius: 8px;
  // padding: 0 10px;
  &>.aCustomerServicePhone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7px 0;
    padding: 4px;
    line-height: 1;
    border-radius: 12px 0 0 12px;
    &>.icon{
      &>img{
        width: 32px;
      }
    }
    &>.customerServiceTelephone{
      text-align: center;
      color: #fff;
    }
  }
}