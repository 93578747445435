.function-component-header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 1.5em;
  margin-top: 8px;
}
.function-component-header .header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.function-component-header .header-content .cycle {
  border-radius: 50%;
  background-color: #a7c2d7;
}
.function-component-header .header-content .big {
  height: 0.5em;
  width: 0.5em;
  margin: 0 8px;
}
.function-component-header .header-content .small {
  height: 0.3em;
  margin: 0 3px;
  width: 0.3em;
}
.function-component-header .more {
  position: absolute;
  font-size: 0.5em;
  color: #6c9bc3;
  right: 3em;
}
.function-component-header .text {
  line-height: 2;
  font-size: 0.7em;
}
