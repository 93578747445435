.page_container {
  padding-bottom: 30px;
  background-color: #eee;
  min-height:100vh;
  box-sizing: border-box;

  :global {
    .comment-detail {
      margin-bottom: 8px;

      .description-show {
        max-width: 270px;
        padding: 15px 0;
        word-break: break-all;
      }

      .za-cell:after {
        left: 0;
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: 15px;
      line-height: 18px;
    }

    .ellipsis-1 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ellipsis-2 {
      -webkit-line-clamp: 2;
      max-height: 36px;
    }

    .ellipsis-3 {
      -webkit-line-clamp: 3;
      max-height: 54px;
    }

    .description-ipt {
      margin-bottom: 8px;
      background-color: #fff;

      .ipt-count {
        text-align: right;
        font-size: 10px;
        padding: 0 16px 8px;
        color: #909090;
      }

      .btns {
        display: flex;
        padding: 10px 0;

        .btn {
          flex: 1;
          text-align: center;
        }
      }
    }

    .process-progress {
      background-color: #fff;
      padding: 15px;
    }

    .progress-steps {
      background-color: #fff;
      border-bottom: 1px solid #e6e6e6;
    }



    .progress-steps {
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      padding: 20px 10px;
      font-size: 12px;
    }

    .step-horizontal {

      .step-container {
        flex: 1;
        position: relative;

        .step-line {
          width: 100%;
          height: 2px;
          background-color: #bbb;
          position: absolute;
          left: 0;
          top: 6px;
          margin-left: 40px;
        }

        &:last-child .step-line {
          display: none;
        }

        .step-dot {
          width: 14px;
          height: 14px;
          background-color: transparent;
          position: absolute;
          left: 30px;
          padding: 2px;

          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #bbb;
          }
        }

        .step-content {
          margin-top: 20px;
          width: 74px;
          text-align: center;
          color: #909090;
        }
      }

      .step-finished {
        .step-line {
          background-color: #89E1C3;
        }

        .step-dot {
          .dot {
            background-color: #12C287;
          }
        }
      }

      .step-doing {
        .step-dot {
          padding: 4px;
          background-color: #12C287;
          border-radius: 50%;

          .dot {
            width: 6px;
            height: 6px;
            background-color: #fff;
          }
        }

        .step-content {
          color: #12C287;
        }
      }
    }

    .journal-steps {
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px 10px;
      font-size: 15px;
    }

    .step-vertical {

      .step-container {
        flex: 1;
        position: relative;

        .step-line {
          width: 2px;
          height: 100%;
          background-color: #bbb;
          position: absolute;
          left: 6px;
          top: 4px;
          margin-top: 7px;
        }

        &:last-child .step-line {
          display: none;
        }

        .step-dot {
          width: 14px;
          height: 14px;
          background-color: transparent;
          position: absolute;
          top: 2px;
          padding: 2px;

          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #bbb;
          }
        }

        .step-content {
          margin: 0 0 18px 20px;
          color: #909090;
          line-height: 18px;

          .content-section {
            margin-bottom: 18px;

            &:after {
              content: '';
              display: block;
              clear: both;
            }

            .k {
              width: 110px;
              float: left;
            }

            .v {
              margin-left: 120px;
            }
          }

          .handle-section {

            &:after {
              content: '';
              display: block;
              clear: both;
            }

            .k {
              width: 200px;
              float: left;
            }

            .v {
              margin-left: 200px;
              text-align: right;
            }
          }
        }
      }

      .step-finished {
        .step-line {
          background-color: #89E1C3;
        }

        .step-dot {
          .dot {
            background-color: #12C287;
          }
        }
      }

      .step-doing {
        .step-line {
          background-color: #89E1C3;
        }

        .step-dot {
          padding: 4px;
          background-color: #12C287;
          border-radius: 50%;

          .dot {
            width: 6px;
            height: 6px;
            background-color: #fff;
          }
        }

        .step-content {
          color: #12C287;
        }
      }
    }

    .option-buttons {
      background-color: #fff;
      height: 50px;
      padding: 7px 0;
      display: flex;
      position: fixed;
      // bottom: 50px;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #eee;
    
      .button {
        flex: 1;
        text-align: center;

        .btn{
          width:110px;
          padding:0;
          border-color:#00bc71;
          color:#00bc71; 
          &.active{
            background-color: #00bc71;
            color: #FFF; 
          }
        }
      }
    }
  }
}

.detailModal {
  :global{
    .journal-detail {
      height: 285px;
      overflow-y: auto;
      word-break: break-all;                                                                                                                                                                                                                     
    }
  
    .t-c {
      text-align: center;
    }
  
    .za-modal__footer {
      padding: 0;
      border-top: 1px solid #e5e5e5;
  
      .sure {
        color: #00bc71
      }
    }
  
    .confirmBtns {
      display: flex;
      width: 100%;
  
      .btn {
        flex: 1;
        font-size: 15px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        color: #666;
  
        &:first-child {
          border-right: 1px solid #e5e5e5
        }
  
        &:last-child {
          color: #00bc71;
        }
      }
    }
  }
}

.selectProgressMan {
  :global{
    .title {
      text-align: center;
      color: #464646;
      font-weight: bold;
      font-size: 17px;
    }
  
    .select-form {
      height: 270px;
      overflow-y: auto;
    }
  }
}

.progressManBtns {
  display: flex;
  width: 100%;
  text-align: center;

  :global{
    .btn {
      flex: 1;
    }
  }
}

.tab_content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  &>.tab_item {
    padding: 2px 13px;
    border: 1px solid #00bc71;
    border-radius: 25px;
    font-size: 13px;
    margin: 5px;
    background: #00bc71;
    color: #ffffff;
  }
}
