.list {
    min-height: 100vh;
    padding: 10px;
    background-color: rgb(236, 236, 236);
    .collect_list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 50px;
        &>.collect_item{
            position: relative;
            width: 48%;
            // height: 200px;
            padding: 0 12px 2px 12px;
            margin-bottom: 10px;
            border-radius: 8px;
            background-color: rgb(255, 255, 255);
            border: 2px solid rgb(236, 236, 236);
            &>.collect_see{
                margin: 0;
                color: #00bc70;
                font-size: 12px;
                text-align: right;
                padding: 3px 0;
            }
            &>.collect_img{
                width: 100%;
                padding-bottom: 15px;
                margin-bottom: 7px;
                border-bottom: 1px solid #eeeeee;
                overflow: hidden;
                &>.icon_border_left, &>.icon_border_right{
                    position: absolute;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #ececec;
                }
                &>.icon_border_left{
                    left: -11px;
                    bottom: 53px;
                }
                &>.icon_border_right{
                    right: -11px;
                    bottom: 53px;
                }
                &>img{
                    // width: 100%;
                    height: 200px;
                    border-radius: 3px;
                }
            }
            &>.collect_name{
                &>.collect_title{
                    font-weight: bold;
                    font-size: 15px;
                    margin: 3px 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                &>.collect_desc{
                    font-size: 13px;
                    margin: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        &>.select_action {
            border: 2px solid #ff7300;
            overflow: hidden;
        }
    }
}

.collect_btn_form {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    &>.collect_btn_text{
        text-align: center;
        color: #00bc70;
        font-size: 17px;
        height: 100%;
        &>.collect_btn_warp_text{
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 8px;
            line-height: 30px;
        }
        &>.collect_btn_text{
            font-size: 11px;
        }
    }
    &>.btn_sub{
        width: 78%;
    }
}