.my-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.my-info .page-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* min-height: 50px; */
  background-position: center 70%;
  justify-content: center;
  align-items: center;
}
.my-info .page-header .avatar {
  width: 80px;
  /* height: 80px;
  transform: translateY(50px); */
  border-radius: 50%;
  border: 2px solid white;
}
.my-info .username {
  /* margin-top: 50px; */
  letter-spacing: 5px;
  line-height: 2;
  padding: 0;
  font-size: 15px;
  font-weight: bold;
}
.my-info .note {
  padding: 0;
  font-size: 0.75em;
  color: #cdcdcd;
  line-height: 1;
  letter-spacing: 1px;
}

.my-info .cell {
  width: 100%;
  line-height: 1;
}
.my-info .cell .iconfont {
  font-size: 1.7em;
  line-height: 1;
  color: #6c9dc4;
}

.Alert_shop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Alert_shop > button {
  width: 48%;
}

.vip_info {
  line-height: 0;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 15px;
}

.vip_info > p {
  margin: 0;
  padding: 10px;
}

.user-Title {
  display: flex;
  justify-content: center;
  align-items: center;
}