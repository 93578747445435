.employee-preorder-list-header-tab {
  background: white;
  ul {
    height: 2em !important;
  }
  li {
    font-size: 0.6em !important;
    line-height: 3em;
  }
}
.employee-preorder-list-calendar {
  max-height: 60vh;
}
.employee-preorder-list {
  background: #e2e2e2;
  .notice-bar {
    background: white;
    border-bottom: 1px solid #e2e2e2;
  }
  min-height: 100vh;
  .page-header {
    z-index: 999;
    background: white;
    border-top: 1px solid #e2e2e2 ;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 2em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .display {
      width: 40%;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      border-left: 1px solid #e2e2e2;
      border-right: 1px solid #e2e2e2;
      p {
        padding: 0;
        margin: 0;
        font-size: 0.7em;
        color: #07be82;
      }
    }
    .prev,
    .next {
      flex-grow: 1;
      text-align: center;
      height: 2em;
      margin: 0 10px;
      line-height: 2;
      font-size: 0.8em;
      border: none;
      border-radius: 0;
    }
  }
}

.employee-preorder-modal {
  button {
    margin-top: 8px;
  }
  .confirm-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      width: 45%;
    }
  }
}
