.content {
    padding: 10px;
    margin-top: 30px;
    .item {
        position: relative;
        font-size: 15px;
        line-height: 1.5;
        padding: 10px;
        margin: 15px 0;
        border-radius: 10px;
        box-shadow: 1px 1px 8px #d7d7d7;
        &>.go_pay{
            position: absolute;
            top: 30%;
            right: 5%;
        }
        &>.item_images{
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding-bottom: 10px;
            border-bottom: 1px solid #eee;
            // height: 200px;
            overflow: hidden;
            // overflow-y: scroll;
            &>div>div{
                text-align: center;
                font-size: 14px;
            }
            &>div>div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 8px;
            }
            &>div>div>img{
                width: 100%;
                // width: 50px;
                // height: 50px;
                // border-radius: 8px;
            }
            &>.loadingMore{
                position: absolute;
                top: 85%;
                width: 100%;
                text-align: center;
            }
        }
        &>.item_price{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 15px;
            padding: 10px 0;
        }
        &>.item_go_pay{
            display: flex;
            justify-content: end;
            padding-top: 10px;
            border-top: 1px solid #eee;
        }
    }
}

.action_bg {
    position: absolute;
    bottom: -10px;
    height: 180px;
    width: 100%;
    text-align: center;
    background-image: linear-gradient(#ffffff26, #ffffff);
    &>div{
        margin: 0 auto;
        font-size: 15px;
        padding-top: 70px;
    }
}