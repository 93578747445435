.theHeadTitle {
  text-align: center;
  margin-top: 50px;
}

.contentBox {
  padding: 10px 0;
}

.userInformationDisplay{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 30px;
  &>.theAvatars{
    width: 100px;
    height: 150px;
    overflow: hidden;
    border-radius: 5px;
    &>img{
      height: 100%;
      border-radius: 5px;
    }
  }
  &>.sSensitiveInformation{
    width: 60%;
    color: #000;
    font-size: 17px;
    &>div{
      margin-top: 5px;
      font-family: cursive;
    }
  }
}

.content {
  padding: 30px;
  font-size: 17px;
  line-height: 2;
  font-family: cursive;
  text-indent:2em
}

.theUserSignature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: cursive;
  padding: 20px 100px 0 30px;
  font-size: 17px;
  &>img{
    transform: rotateZ(270deg);
    position: absolute;
    width: 62px;
    right: 35px;
  }
}

.signingDate {
  font-family: serif;
  font-size: 18px;
  text-align: right;
  padding: 30px;
}

.signButton {
  position: fixed;
  width: 90%;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
}

.actionButtons {
  position: fixed;
  justify-content: space-between;
  z-index: 10000;
  height: 43%;
  transform: rotateZ(90deg);
  right: 159px;
  bottom: -119px;
  display: flex;
  flex-direction: column;
}

.signatureToSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 45px;
  color: #fff;
  background: hsl(156deg 100% 37%);
  border-radius: 25px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.signatureToClear {
  height: 50px;
  width: 50px;
  margin-top: 18px;
  background-color: hsl(156deg 100% 37%);
  border-radius: 50%;
  padding: 10px;
  &>img{
    width: 100%;
  }
}

.buttonToReturnTo {
  font-size: 14px;
    text-align: center;
    line-height: 30px;
    color: #fff;
}