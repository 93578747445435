.content{
  height: 120vh;
}

.title {
  text-align: center;
  padding: 10px 0;
  color: #fff;
  background-color: #00b904;
  // margin-bottom: 10px;
}

.id_title {
  border-top: 1px solid #eee;
  padding: 10px 20px;
  font-size: 13px;
}

.images_upload_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  &>.images_left{
    width: 150px;
    // height: 80px;
    text-align: center;
    // line-height: 4.5;
    overflow: hidden;
    background-color: #eee;
  }
  &>.images_right{
    // background: #00b904;
  }
}

.images_thumbnail {

}

.tipes_show_Text {
  text-align: center;
}

.images_right {
  width: 80px;
  &>img{
    width: 100%;
  }
}

.service_mobile {
  position: fixed;
  right: 3%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 50%;
  box-shadow: -1px 1px 8px #cecece;
  &>img{
    width: 100%;
  }
}

.sub_btn {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}