.Content_tab {
    padding: 60px 10px 0 10px;
    &>.list{
        width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
    }
}

.item {
    width: 49%;
    // height: 100px;
    margin-bottom: 7px;
    padding-bottom: 6px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid rgb(187, 187, 187);
    overflow: hidden;

    &>.images_title {
        // min-width: 175px;
        width: 100%;
        height: 140px;
        overflow: hidden;
        // display: flex;
        // justify-content: center;
        &>img{
            width: 100%;
        }
    }

    &>.info_item {
        font-size: 12px;
        padding: 0 5px;

        &>.title {
            padding: 5px 0;
            font-size: 16px;
            font-weight: bold;
        }

        &>.desc_integral {
            font-size: 12px;
            text-align: right;
            padding-right: 10px;
            &>.action_size_font {
                width: 32px;
                font-size: 17px !important;
                font-weight: bold;
                color: #00B26A;
                padding-left: 5px;
            }
        }
    }
}

.desc_info {
    font-size: 12px;
    color: #888888;
    &>span {
        display: inline-block;
        width: 56px;
        // text-align-last: justify;
        margin-right: 3px;
    }
}

.integral {
    padding: 15px;
    border-bottom: 10px solid #eee;

    .integral_row {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        .integral_number {
            color: #ff007f;
            font-size: 25px;
            font-weight: bold;
        }

        .integral_text {
            color: #ff007f;
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.info {
    padding: 10px;
    padding-top: 10px;

    &>.title {
        font-size: 18px;
        font-weight: bold;
    }

    &>.info_cell {
        margin-top: 7px;

        &>.cell {
            display: flex;
            flex-direction: column-reverse;
            // justify-content: flex-start;
            // align-items: center;
            padding: 10px 0;
            // border-bottom: 1px solid #eee;

            &>.left_text {
                // width: 65px;
                font-size: 14px;
                color: #7d7d7d;
            }
        }
    }
}

.settlement {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    &>.btn_sub {
        width: 30%;
        line-height: 1;
        text-align: center;
        color: #fff;
        padding: 10px;
        border-radius: 25px;
    }
}

.detail_images {
    padding-bottom: 100px;

    &>img {
        width: 100%;
    }
}

.detaile_list {
    padding: 0 13px;
    font-size: 14px;
    margin-bottom: 10px;

    &>.taile_title {
        color: #424242;
        font-size: 18px;
        padding: 5px 0;
    }
}

.lable_text {
    color: #7d7d7d;
    margin-right: 5px;
    text-align-last: justify;
    width: 70px;
}

.content_b {
    width: 100%;
    padding: 40px;

    &>.list_count {
        padding: 10px;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #b3b3b3;

        &>.row_n {
            font-size: 15px;
            text-align: center;
            margin: 10px 0;

            &>.num {
                color: #00B26A;
                font-size: 25px;
                font-weight: bold;
                margin: 0 10px;
            }
        }
    }

    &>.exchange_content {
        margin-top: 25px;

        &>.title {
            font-size: 16px;
            margin-bottom: 10px;
        }

        &>.list_amount {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &>.item {
                width: 85px;
                padding: 7px 20px;
                text-align: center;
                background-color: #FFFFFF;
                border-radius: 8px;
                margin-bottom: 10px;
            }
        }

        &>.list_desc {
            display: flex;
            justify-content: space-between;
            word-break: break-all;
            border-radius: 8px;
            background: #fff;
            padding: 10px;
            margin-top: 15px;

            &>.label_text {
                width: 70px;
                font-size: 15px;
            }

            &>.text_desc {
                width: 70%;
                font-size: 14px;
            }
        }
    }

    &>.btn_f {
        margin-top: 20px;

        &>.btn_grif {
            width: 100%;
            color: #fff;
            background-color: #00B26A;
        }
    }
}

.get_points {
    position: fixed;
    bottom: 10%;
    right: 3%;
    width: 45px;
    height: 45px;
    font-size: 13px;
    color: #fff;
    border-radius: 50%;
    background-color: #00B26A;
    text-align: center;
    padding: 9px;
}

.get_points_code {
    position: fixed;
    bottom: 24%;
    right: 3%;
    width: 45px;
    height: 45px;
    font-size: 13px;
    color: #fff;
    border-radius: 50%;
    background-color: #00B26A;
    text-align: center;
    padding: 6px;
}

.get_points_url {
    position: fixed;
    bottom: 24%;
    right: 3%;
    width: 45px;
    height: 45px;
    font-size: 13px;
    color: #fff;
    border-radius: 50%;
    background-color: #00B26A;
    text-align: center;
    padding: 6px ;
}

.shar_btn {
    position: fixed;
    width: 70%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 21%;
    &>button{
        background-color: #00B26A !important;
    }
}

.shar_btn_guide {
    position: fixed;
    width: 70%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 13%;
}

.guide {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;

    &>img {
        width: 100%;
        height: 100%;
    }
}

.proudct_item_area{
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
    
.proudct_item_img{
    width: 100%;
}