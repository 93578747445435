body {
  height: 100vh;
}

.content {
  width: 100%;
  // height: 100vh;
  & > .iten_content {
    position: relative;
    width: 61%;
    margin: 0 auto;
    display: block;
    margin-top: 40%;
    & > img {
      width: 100%;
    }
  }
}

.selec_list {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-direction: column-reverse;
  & > img {
    width: 180px;
    height: 180px;
    margin: 10px;
    border: 8px solid #f9baba;
    transform: rotate(90deg);
  }
  & > .close_icon {
    position: fixed;
    width: 30%;
    right: 0;
    bottom: 0;
    z-index: 10;
    & > img {
      width: 50px;
    }
  }
}

.content_box {
  width: 100%;
  height: 100vh;
  padding-top: 10px;
  background: rgb(105, 105, 105);
}

.images_content {
  width: 250px;
  height: 500px;
  margin: 0 auto;
  position: relative;
}

.img_select {
  width: 100%;
  // height: 100%;
  //   transform: rotate(90deg);
}

.block_1_one {
  width: 36%;
  height: 14%;
  background: red;
  top: 22%;
  left: 29%;
  position: absolute;
  z-index: 9;
  overflow: hidden;
}

.block_1_top {
  width: 36%;
  height: 14%;
  // background: red;
  top: 22%;
  left: 29%;
  position: absolute;
  z-index: 11;
}

.block_2_one {
  width: 57%;
  height: 40%;
  background: red;
  top: 5%;
  left: 22%;
  position: absolute;
  z-index: 9;
  overflow: hidden;
}

.block_2_top {
  width: 57%;
  height: 40%;
  // background: red;
  top: 5%;
  left: 22%;
  position: absolute;
  z-index: 11;
}

.block_3_two {
  width: 25%;
  height: 19%;
  background: red;
  top: 57%;
  left: 25%;
  position: absolute;
  z-index: 9;
  overflow: hidden;
}

.block_3_top {
  width: 25%;
  height: 19%;
  // background: red;
  top: 57.5%;
  left: 25%;
  position: absolute;
  z-index: 11;
}

.block_4_three {
  width: 25%;
  height: 19%;
  background: red;
  top: 74.5%;
  left: 25%;
  position: absolute;
  z-index: 9;
  overflow: hidden;
}

.block_4_top {
  width: 25%;
  height: 19%;
  top: 74.5%;
  left: 25%;
  position: absolute;
  z-index: 11;
}

.block_5 {
  width: 25%;
  height: 19%;
  background: red;
  top: 57%;
  left: 50%;
  position: absolute;
  z-index: 9;
  overflow: hidden;
}

.block_5_top {
  width: 25%;
  height: 19%;
  // background: red;
  top: 57%;
  left: 50%;
  position: absolute;
  z-index: 11;
}

.block_6 {
  width: 25%;
  height: 19%;
  background: red;
  top: 74.5%;
  left: 50%;
  position: absolute;
  z-index: 9;
  overflow: hidden;
}

.block_6_top {
  width: 25%;
  height: 19%;
  top: 74.5%;
  left: 50%;
  position: absolute;
  z-index: 11;
}

.block_7 {
  width: 92%;
  height: 45.5%;
  background: red;
  position: absolute;
  top: 2.5%;
  left: 5.5%;
  z-index: 9;
  overflow: hidden;
}

.block_7_top {
  width: 91%;
  height: 45.5%;
  position: absolute;
  top: 2.5%;
  left: 5%;
  z-index: 11;
}

.block_8 {
  width: 61%;
  height: 29.5%;
  background: red;
  position: absolute;
  top: 61.5%;
  left: 22%;
  z-index: 9;
  overflow: hidden;
}

.block_8_top {
  width: 61%;
  height: 29.5%;
  position: absolute;
  top: 61.5%;
  left: 22%;
  z-index: 11;
}

.block_9 {
  width: 30%;
  height: 23%;
  background: red;
  position: absolute;
  top: 10%;
  left: 6%;
  z-index: 9;
  overflow: hidden;
}

.block_9_top {
  width: 30%;
  height: 23%;
  position: absolute;
  top: 10%;
  left: 6%;
  z-index: 11;
}

.block_10 {
  width: 29%;
  height: 24%;
  background: red;
  position: absolute;
  top: 10%;
  left: 35.5%;
  z-index: 9;
  overflow: hidden;
}

.block_10_top {
  width: 29%;
  height: 24%;
  position: absolute;
  top: 10%;
  left: 35.5%;
  z-index: 11;
}

.block_11 {
  width: 29%;
  height: 25%;
  background: red;
  position: absolute;
  top: 10%;
  left: 64.5%;
  z-index: 9;
  overflow: hidden;
}

.block_11_top {
  width: 29%;
  height: 25%;
  position: absolute;
  top: 10%;
  left: 64.5%;
  z-index: 11;
}

.block_12 {
  width: 86%;
  height: 64.6%;
  background: #000000;
  position: absolute;
  top: 34.5%;
  left: 7.5%;
  z-index: 9;
  overflow: hidden;
}

.block_12_top {
  width: 86%;
  height: 64.6%;
  position: absolute;
  top: 34.5%;
  left: 7.5%;
  z-index: 11;
}

.block_13 {
  width: 71%;
  height: 22.3%;
  background: red;
  position: absolute;
  top: 3.5%;
  left: 17.5%;
  z-index: 9;
  overflow: hidden;
}

.block_13_top {
  width: 71%;
  height: 22.3%;
  position: absolute;
  top: 3.5%;
  left: 17.5%;
  z-index: 11;
}

.block_14 {
  width: 71%;
  height: 22.3%;
  background: red;
  position: absolute;
  top: 25.8%;
  left: 17.5%;
  z-index: 9;
  overflow: hidden;
}

.block_14_top {
  width: 71%;
  height: 22.3%;
  position: absolute;
  top: 25.8%;
  left: 17.5%;
  z-index: 11;
}

.block_15 {
  width: 100%;
  height: 37.3%;
  background: red;
  position: absolute;
  top: 58%;
  left: 0;
  z-index: 9;
  overflow: hidden;
}

.block_15_top {
  width: 100%;
  height: 37.3%;
  position: absolute;
  top: 58%;
  left: 0;
  z-index: 11;
}

.block_16 {
  width: 60%;
  height: 42.3%;
  background: red;
  position: absolute;
  top: 6%;
  left: 28%;
  z-index: 9;
  overflow: hidden;
}

.block_16_top {
  width: 60%;
  height: 42.3%;
  position: absolute;
  top: 6%;
  left: 28%;
  z-index: 11;
}

.block_17 {
  width: 60%;
  height: 42.3%;
  background: red;
  position: absolute;
  top: 51.5%;
  left: 14%;
  z-index: 9;
  overflow: hidden;
}

.block_17_top {
  width: 60%;
  height: 42.3%;
  position: absolute;
  top: 51.5%;
  left: 14%;
  z-index: 11;
}

.block_18 {
  width: 74%;
  height: 68.3%;
  background: red;
  position: absolute;
  top: 15.5%;
  left: 13%;
  z-index: 9;
  overflow: hidden;
}

.block_18_top {
  width: 74%;
  height: 68.3%;
  position: absolute;
  top: 15.5%;
  left: 13%;
  z-index: 11;
}

.block_19 {
  width: 35%;
  height: 28.3%;
  background: red;
  position: absolute;
  top: 11%;
  left: 9%;
  z-index: 9;
  overflow: hidden;
}

.block_19_top {
  width: 35%;
  height: 28.3%;
  position: absolute;
  top: 11%;
  left: 9%;
  z-index: 11;
}

.block_20 {
  width: 35%;
  height: 28.3%;
  background: red;
  position: absolute;
  top: 11%;
  left: 47%;
  z-index: 9;
  overflow: hidden;
}

.block_20_top {
  width: 35%;
  height: 28.3%;
  position: absolute;
  top: 11%;
  left: 47%;
  z-index: 11;
}

.block_21 {
  width: 100%;
  height: 50%;
  background: red;
  position: absolute;
  top: 49%;
  left: 0;
  z-index: 9;
  overflow: hidden;
}

.block_21_top {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 49%;
  left: 0;
  z-index: 11;
}

.block_22 {
  width: 60%;
  height: 29%;
  background: red;
  position: absolute;
  top: 9%;
  left: 20%;
  z-index: 9;
  overflow: hidden;
}

.block_22_top {
  width: 60%;
  height: 29%;
  position: absolute;
  top: 9%;
  left: 20%;
  z-index: 11;
}

.block_23 {
  width: 100%;
  height: 50%;
  background: red;
  position: absolute;
  top: 49%;
  left: 0;
  z-index: 9;
  overflow: hidden;
}

.block_23_top {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 49%;
  left: 0;
  z-index: 11;
}

.block_24 {
  width: 41%;
  height: 32%;
  background: red;
  position: absolute;
  top: 4.5%;
  left: 9%;
  z-index: 9;
  overflow: hidden;
}

.block_24_top {
  width: 41%;
  height: 32%;
  position: absolute;
  top: 4.5%;
  left: 9%;
  z-index: 11;
}

.block_25 {
  width: 41%;
  height: 32%;
  background: red;
  position: absolute;
  top: 4.5%;
  left: 50%;
  z-index: 9;
  overflow: hidden;
}

.block_25_top {
  width: 41%;
  height: 32%;
  position: absolute;
  top: 4.5%;
  left: 50%;
  z-index: 11;
}

.block_26 {
  width: 81%;
  height: 58%;
  background: red;
  position: absolute;
  top: 36.5%;
  left: 10%;
  z-index: 9;
  overflow: hidden;
}

.block_26_top {
  width: 81%;
  height: 58%;
  position: absolute;
  top: 36.5%;
  left: 10%;
  z-index: 11;
}

// // // // // // // // // // // // // // // // // // // //
// // // // // // // // // // // // // // // // // // // // // // //
// // // // // // // // // // // // // // // // // // // // //
// // // // // // // // // // // // // // // // // // // // // //
// // // // // // // // // // // // // // // // // // // // // // // // // //
// // // // // // // // // // // // // // // // // // // // // //

.pageBackground {
  background-color: #b7b7b7;
}

.content_body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  & > .content_images {
    width: 250px;
    position: relative;
    height: 500px;
    margin: 0 auto;
    margin-top: 15vh;
    // margin-top: 15%;
    overflow: hidden;
    & > img {
      border: 2px solid #000;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 5;
    }
  }
}

.block_1_1 {
  // width: 133px;
  // height: 187px;
  background: #b8b8b8;
  position: absolute;
  // top: 32px;
  // left: 58px;
  z-index: 2;
  overflow: hidden;
  & > img {
    height: 100%;
  }
}

.block_1_1_top {
  // width: 133px;
  // height: 187px;
  position: absolute;
  // top: 32px;
  // left: 58px;
  z-index: 11;
}

.btn_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  transform: rotate(90deg);
  position: fixed;
  bottom: 66.5%;
  left: -27%;
  z-index: 10000;
}

.text_update {
  transform: rotate(90deg);
  display: inline-block;
  position: absolute;
  top: 94px;
  left: 39px;
  font-weight: bold;
  font-size: 11px;
}

.btn_menu_save {
  position: fixed;
  right: 7%;
  bottom: 5%;
  z-index: 10;
  transform: rotate(90deg);
}

.username_one {
  position: absolute;
  z-index: 11;
  transform: rotate(90deg);
  top: 41%;
  font-size: 12px;
  left: 43%;
  width: 50px;
}

.time_one {
  position: absolute;
  z-index: 11;
  transform: rotate(90deg);
  top: 76%;
  font-size: 12px;
  left: 10%;
}

.class_one {
  position: absolute;
  z-index: 11;
  transform: rotate(90deg);
  top: 78%;
  font-size: 12px;
  left: 27%;
  top: 73%;
  font-size: 12px;
  left: 14%;
}

.guding_text {
  position: absolute;
  z-index: 11;
  transform: rotate(90deg);
  top: 67%;
  font-size: 13px;
  // left: -15%;
  right: -384px;
  width: 829px;
  color: #626262;
  line-height: 1.5;
}

.images_combineUrl {
  border: 5px solid #000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  & > img {
    width: 100%;
    height: 100%;
  }
}

.tipes_null {
  font-size: 15px;
  text-align: center;
  margin-top: 32px;
  color: #979797;
  &>div{
    width: 100%;
    word-break: break-all;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}

.menu_list {
  position: fixed;
  bottom: -8%;
  right: 35%;
  transform: rotate(90deg);
  height: 35%;
}

.back_btn {
  border-radius: 25px;
  // position: fixed;
  z-index: 1000;
  // transform: rotate(90deg);
  // bottom: -0.8rem;
  bottom: -0.14rem;
  right: 60%;
  width: 45px;
  color: #fff;
  height: 54%;
  font-size: 17px;
  padding: 6px;
}

.back_btn_update {
  // position: fixed;
  z-index: 1000;
  // transform: rotate(90deg);
  bottom: -0.14rem;
  right: 22%;
  width: 50px;
  height: 50px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
}

.back_btn_sub_btn {
  // position: fixed;
  z-index: 1000;
  margin: 10px 0;
  // transform: rotate(90deg);
  bottom: -0.14rem;
  right: 5%;
  line-height: 1.3;
}

.back_btn_sub {
  // position: fixed;
  z-index: 1000;
  // transform: rotate(90deg);
  margin: 10px 0;
  bottom: 7%;
  right: 5%;
  width: 50px;
  height: 50px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  color: rgb(64 64 64);
  background-color: #fff;
  line-height: 1.3;
}

.null_ele {
  // width: 50px;
  // height: 50px;
}

.customerServiceTelephone {
  display: block;
  position: fixed;
  z-index: 100;
  width: 45px;
  height: 45px;
  right: 5%;
  top: 10%;
  border-radius: 50%;
  padding: 5px;
  background: hsl(156deg 100% 37%);
  &>img{
      width: 100%;
  }
}


.help_images {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  &>img{
    width: 100%;
    height: 100%;
  }
}

.theAlbumCover {
  word-break: break-all;
  width: 129px;
}

.box_content {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  justify-content: space-around;
  flex-direction: row-reverse;
  padding-bottom: 150px;
  &>div{
    position: relative;
    height: 40%;
    margin: 10px;
    &>div{
      width: 93%;
      transform: rotate(90deg);
      position: absolute;
      top: 20%;
      right: -36%;
      font-size: 13px;
      overflow: hidden;
    }
    &>img{
      width: 100%;
      // height: 100%;
      border: 5px solid #fff;
    }
  }
}

.selectView_list {
  background: rgb(236, 236, 236);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  height: 60vh !important;
  // overflow: hidden;
}

.progress_tipes {
  position: fixed;
  bottom: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80%;
  // background-color: red;
  &>.tipes_pro {
    text-align: left;
    font-size: 12px;
  }
}


.upgradePackageContent {
  position: relative;
  height: 80vh;
  // background: #ffa500;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  &>.closeThePackages{
      padding: 10px;
      text-align: end;
  }
  &>.thePackageList{
      overflow-y: scroll;
      height: 100vh;
      padding-bottom: 300px;
  }
  &>.no_design{
      position: absolute;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;
      padding: 10px;
  }
}


.packageContent {
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  &>.packageName{
      padding: 5px 10px;
      font-size: 17px;
      line-height: 1;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
      border-radius: 25px;
      background-color: #ffb100;
  }
  &>img{
      width: 100%;
      display: block;
  }
  &>.paymentButton{
      margin-top: 10px;
  }
}

.text_tipes {
  font-size: 13px;
  margin: 0 11px;
  color: #fff;
  font-weight: bold;
  padding: 10px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.pageSize_text {
  font-size: 15px;
   white-space: nowrap;
   overflow: hidden;   
   text-overflow: ellipsis;
   width: 140px;
  text-align: center;
}

.thatList {
  font-size: 15px;
  padding: 5px 0;
  &>div{
    padding: 5px 0;
  }
}

// 毕业季卡片
.filmCards {
  padding: 10px;
  &>.filmTitle{
    font-size: 14px;
  }
  .filmContent {
    padding: 10px 0;
  }
}


.waterMaker{
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 999;
 }