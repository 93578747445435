.custom-control {
  font-size: 0.5em;
  color: #888888;
  padding-bottom: 15px;
  width: 100%;
}
.my-preorder {
  .my-preorder-container {
    overflow-y: auto;
    max-height: calc(95vh);
  }
  .my-preorder-header {
    width: 100%;
    background: white;
    box-shadow: 0 0 15px #888888;
    .panel {
      font-size: 0.7em;
    }
  }
  .my-preorder-card {
    background: white;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    align-items: center;
    flex-direction: row;
    .icon-area {
      width: 20%;
      height: 100%;
      background: violet;
    }
    .type-area{
      border-left: 1px solid #00bc71;
      padding-left: 10px;
      font-size: 15px;
    }
    .text-area {
      height: 100%;
      line-height: 1.5;
      font-size: 15px;
      flex-grow: 1;
      .title {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .date {
        font-size: 13px;
        color: #666666;
      }
    }
  }
}
