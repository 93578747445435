.container{
  background-color: #f5f5f5;
  min-height:100vh;
  padding-bottom:50px;
  :global{
    .detai-container{
      background-color: #fff;
      margin-bottom:10px;

      .section{
        padding:15px;
        line-height:20px;
        .k{
          float:left;
          font-size:15px;
          color:#464646;
        }
        .v{
          margin-left:100px;
          font-size:15px;
          color:#464646;
        }
        &::after{
          content:'';
          display:block;
          clear:both;
        }
      }
    }  
    
    .photo-list{
      background-color: #fff;
      padding:15px 15px 0;
      margin-bottom:10px;

      .picture-item{
        float:left; 
        width:70px;
        height:70px;
        margin:0 15px 15px 0; 
        position:relative;

        img{
          width:100%;
          height:100%;
          object-fit: cover;
        }

        .delete-btn{
          position:absolute;
          top:-10px;
          right:-10px;
          color:rgba(0,0,0,.5);
          border-radius: 50%;
          z-index: 5;
        }
      }
      &::after{
        content: '';
        display: block;
        clear: both;
      }
    }

    .foot-option{
      position:fixed;
      bottom:0;
      width:100%;
      background-color: #fff;
      height:50px;
      display: flex;
      padding:7px 0;
      box-sizing: border-box;

      .button{
        flex:1;
        text-align: center;
      }
    }

    .journal-steps {
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;
      font-size: 15px;
      margin-bottom:10px;
    }

    .step-vertical {

      .step-container {
        flex: 1;
        position: relative;

        .step-line {
          width: 2px;
          height: 100%;
          background-color: #bbb;
          position: absolute;
          left: 6px;
          top: 4px;
          margin-top: 7px;
        }

        &:last-child .step-line {
          display: none;
        }

        .step-dot {
          width: 14px;
          height: 14px;
          background-color: transparent;
          position: absolute;
          top: 2px;
          padding: 2px;

          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #bbb;
          }
        }

        .step-content {
          margin: 0 0 18px 20px;
          color: #909090;
          line-height: 18px;

          .content-section {
            margin-bottom: 18px;

            &:after {
              content: '';
              display: block;
              clear: both;
            }

            .k {
              width: 110px;
              float: left;
            }

            .v {
              margin-left: 120px;
            }
          }

          .handle-section {


            &:after {
              content: '';
              display: block;
              clear: both;
            }

            .k {
              width: 200px;
              float: left;
            }

            .v {
              margin-left: 200px;
              text-align: right;
            }
          }
        }
      }

      .step-finished {
        .step-line {
          background-color: #89E1C3;
        }

        .step-dot {
          .dot {
            background-color: #12C287;
          }
        }
      }

      .step-doing {
        .step-line {
          background-color: #89E1C3;
        }

        .step-dot {
          padding: 4px;
          background-color: #12C287;
          border-radius: 50%;

          .dot {
            width: 6px;
            height: 6px;
            background-color: #fff;
          }
        }

        .step-content {
          color: #12C287;
        }
      }
    }

    .file-picker-btn{
      width:70px;
      height:70px;
      float:left;
      border:1px dotted #aaaaaa;
      text-align: center;
      margin-bottom: 15px;
      border-radius: 4px;

      .za-icon{
        line-height: 70px;
      }

      .needsclick {
        width: 68px;
        height: 68px;
      }
    }
  }
}

.detailModal {
  :global{
    .journal-detail {
      height: 285px;
      overflow-y: auto;
      word-break: break-all;                                                                                                                                                                                                                     
    }
  
    .t-c {
      text-align: center;
    }
  
    .za-modal__footer {
      padding: 0;
      border-top: 1px solid #e5e5e5;
  
      .sure {
        color: #00bc71
      }
    }
  
    .confirmBtns {
      display: flex;
      width: 100%;
  
      .btn {
        flex: 1;
        font-size: 15px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        color: #666;
  
        &:first-child {
          border-right: 1px solid #e5e5e5
        }
  
        &:last-child {
          color: #00bc71;
        }
      }
    }
  }
}