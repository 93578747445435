.close-relationship-number-modal-line {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px;
  border-top: 1px solid #c1c1c1;
}
.close-relationship-number-modal-line .button {
  height: 50px;
  color: rgb(24, 144, 255);
}
