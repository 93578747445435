.Search_warp{
    position: relative;
    padding: 20px 10px 5px 10px;
    &>.Search_input{
        width: 76%;
        height: 100%;
        margin: 0 auto;
        padding: 0 10px;
        border-radius: 25px;
        border: 1px solid;
        display: block;
    }
    &>.Search_icon{
        position: absolute;
        right: 17%;
        bottom: 10px;
        width: 21px;
        height: 21px;
    }
}

.Test_content{
    padding: 10px;
    margin-bottom: 10px;
    &>.Test_item{
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 10px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 10px;
        &>.Test_info{
            font-size: 13px;
            width: 100%;
            &>div{
                padding: 2px 0;
            }
            &>.Test_name {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            &>.Test_num_time{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                font-size: 15px;
                padding: 5px 0;
            }
            &>.Test_btn_list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                &>.Test_btn_style{
                    color: red;
                    padding: 3px 5px;
                    border: 1px solid #000000;
                    border-radius: 5px;
                    letter-spacing: 5px;
                }
            }
        }
        &>.Test_num{
            font-size: 15px;
            border: 1px solid;
            padding: 10px;
            text-align: center;
            border-radius: 8px;
            &>div{
                margin: 0;
            }
            &>.Test_max_text{
                font-size: 12px;
                padding: 3px 0;
            }
            &>.Test_max_num{
                color: red;
                font-size: 23px;   
            }
        }
    }
}

.Test_Text_null {
    text-align: center;
    line-height: 5;
    font-size: 14px;
}