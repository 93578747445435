.preorder-detail-from-employee-photographer-modal-checkbox {
  max-height: 45vh;
}
.preorder-detail-from-employee-clothe-picker {
  background: white;
  line-height: 2;
  .title {
    min-width: 4em !important;
  }
  .row {
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.employee-preorder-detail {
  padding-bottom: 50px;
  .tag-container {
    display: flex;
    width: 100%;
    background: white;
    flex-wrap: wrap;
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #e2e2e2;
    .tag {
      padding: 2px 8px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 10px;
      border-radius: 999px;
      font-size: 15px;
      border: 1px solid black;
    }
  }
  .selection_info {
    background: white;
    font-size: 0.7em;
    padding: 5px;
  }
  .standard-image-container {
    background: white;
    padding: 15px;
    .row {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
    }
    .tags {
      display: flex;
      flex-direction: column;
    }
    span {
      padding: 5px 15px;
      font-size: 15px;
    }
    img {
      max-width: 6em;
    }
  }
  background: #e2e2e2;
  min-height: 100vh;
  .base-info {
    display: flex;
    background-color: white;
    flex-direction: row;
    font-size: 0.7em;
    line-height: 1.8;
    flex-wrap: wrap;
    padding: 10px;
    div {
      width: 50%;
    }
  }
  .clothe {
    margin-top: 10px;
    .clothe-container {
      display: flex;
      flex-direction: row;
      background: white;
      width: 100%;
      padding: 8px;
      justify-content: space-between;
      flex-wrap: wrap;
      img {
        width: 45%;
        margin: 5px;
        height: auto;
      }
    }
  }
  .footer-button-row {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    position: fixed;
    bottom: 1em;
    width: 100%;
    justify-content: space-between;
    button {
      font-size: 0.7em;
    }
  }
}
