.custom {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  height: 100%;

  .custom__date {}

  .custom__text {
    font-size: 0.3em;
  }

  .red {
    color: #dc143c;
  }

  .green {
    color: #00ced1;
  }
}

.customer-info-modal {
  background: white;

  .calendar {
    max-height: 40vh;
  }

  input,
  .input-like {
    background: #f6f6f6;
    border-radius: 10px;
    margin-top: 0.5em;
    padding: 1em;
    height: 3em;
  }
}

.month-button-row {
  display: flex;
  background: white;
  padding: 0.5em;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .main {
    font-size: 0.6em;
  }

  .month-button {
    width: 30%;
    font-size: 0.6em;
  }
}

.preorder-schedule {
  .blank-divider {
    height: 15px;
    background-color: white;
    width: 100%;
  }

  .more-button {
    line-height: 1.5;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;

    .iconfont {
      font-size: 1.2em;
    }
  }

  .already-preorder-card {
    font-size: 0.7em;
    width: 95%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-left: 2.5%;
    display: flex;
    margin-top: 15px;
    flex-direction: column;

    .more {
      background-color: #f0f3f5;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      font-size: 1em;
      line-height: 2.5;
      color: #979797;
    }

    .more>div {
      text-align: center;
      width: 33%;

    }

    .more .active {
      width: 33%;
      text-align: center;
      border-bottom: 4px solid #07be82;
      color: #07be82;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
        padding-left: 10px;
        line-height: 0.5;
      }

      .button {
        padding-right: 10px;
        margin-top: 10px;

        button {
          font-size: 0.8em;
          height: 3em;
          color: #03bd7f;
          border: 1px solid #03bd7f;
        }
      }
    }
  }

  .my-already-preorder {
    background: white;
  }

  background-color: #f0f3f5;
  min-height: 100vh;

  .button-row {
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0;

    button {
      font-size: 0.6em;
      width: 100%;
      border-radius: 0;
      border: none;
    }

    .main {
      width: 35vw;
    }
  }

  .time-picker-no-result {
    min-height: 60vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .time-picker-loading {
    min-height: 60vh;
    width: 100%;
    background-color: #f0f3f5;
  }

  .time-picker {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;

    .time-block {
      background-color: #fff;
      margin-top: 1em;
      margin-bottom: 1em;
      width: 45vw;
      border-radius: 8px;
      height: 23vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-size: 0.8em;

      .time-block-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 5px;
        height: 100%;

        .second-line {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p {
            font-size: 11px;
            padding-bottom: 5px;
            text-align-last: right;
            margin: 0;
            width: 8em;
            color: #888888;
          }
        }

        .first-line {
          display: flex;
          width: 100%;
          max-height: 35px;
          flex-direction: row;
          justify-content: flex-end;

          .time-note {
            margin: 0;
            width: 24px;
            font-size: 12px;
            padding-top: 5px;
          }

          .time-value {
            margin: 0;
            font-size: 32px;
            padding-left: 3px;
            font-weight: bold;
            line-height: 1;
          }
        }
      }

      .time-block-panel {
        color: white;
        font-weight: bold;
        width: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 100%;

        p {
          width: 1em;
        }
      }
    }

    .time-block-can-preorder {
      .time-block-panel {
        background: #00bc71;
      }

      &.time-block-selected {
        box-shadow: 0 0 0 1px #00bc71;
      }
    }

    .time-block-can-queue {
      .time-block-panel {
        background: #f97b7c;
      }

      &.time-block-selected {
        box-shadow: 0 0 0 1px #f97b7c;
      }
    }

    .time-block-already-out-date {
      .time-block-panel {
        background: #d4d4d4;
      }

      &.time-block-selected {
        box-shadow: 0 0 0 1px #d4d4d4;
      }
    }

    .time-block-already-preorder {
      .time-block-panel {
        background: #6bb2bc;
      }

      &.time-block-selected {
        box-shadow: 0 0 0 1px #6bb2bc;
      }
    }

    .time-block-check {
      .time-block-panel {
        background: #eeb55c;
      }

      &.time-block-selected {
        box-shadow: 0 0 0 1px #eeb55c;
      }
    }
  }

  .calendar-month {
    position: relative;
    padding-bottom: 3em;
    min-height: 30vh;

    .loading {
      position: absolute;
      z-index: 888;
      width: 100%;
      background-color: rgba(146, 146, 146, 0.1);
      min-height: 100%;
      top: 0;
      left: 0;
    }

    .za-calendar__day--today {
      background-color: #e5fff5;
    }

    .za-calendar__day--selected {
      background-color: #00bc71;

      .red {
        color: white !important;
      }

      .green {
        color: white !important;
      }
    }

    .za-calendar__day__content {
      height: 43px;
    }
  }

  .result {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;

    span {
      font-size: 0.8em;
    }
  }
}

.za-calendar__body {}

.tag_item {
  color: #888888;
  font-size: 12px;
  padding: 2px 4px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin: 5px;
}

.tag_item_activit {
  color: #fff !important;
  border-color: #00bc71 !important;
  background: #00bc71;
}

.view_schedule {
  padding: 10px;
  margin-top: 20px;
}

.view_schedule_content {
  max-height: 90vh;
  font-size: 12px;
  padding: 10px;
  background: #fff;
  overflow-y: scroll;
}

.close_schedule_content {
  padding: 3px 5px;
  border: 1px solid;
  display: inline-block;
  margin: 5px;
  text-align: center;
  line-height: 1;
  border-radius: 4px;
  color: #d43737;
}

.view_schedule_content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.schedule_text {
  padding-left: 13px;
  color: #9f9f9f;
}

.tag_content_ {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}