.bodyBox{
    width: 95%;
    background: #000;
    margin: 0 auto;
    padding-bottom: 15px;
    z-index: 10;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px 1px 2px #c5c5c5;
}
 /* 头部标题 */
.head{ 
    list-style: none;
    margin: 0;
    padding: 0;
    height: 40px;
}
.head li{
    float: left;
    width: 200px;
    line-height: 40px;
    text-align: center;
}
.parent {
    width: 100%;
    height: 20vh;
    /* height: 200px; */
    overflow:hidden
}
/*设置的子盒子高度大于父盒子，产生溢出效果*/
.child {
    height: auto;
    list-style: none;
    margin: 0;
    padding: 0;
}
.child li {
    /* background: #009678; */
    text-align: center;
    border-bottom: 2px solid #fff;
}

.comminChild>li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px;
}

.comminChild>li>div>img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.user_name {
    font-size: 15px;
    margin-left: 10px;
}

.bullet-chat{
    height: 30vh;
    position: fixed;
    top: 0;
    width: 100%;
}

.bullet-chat div{
    /* position: absolute; */
    /* transition: left linear 8s; */
    /* z-index: 10; */
    position: absolute;
    right: 0;
    /* transform: translateX(-160px); */
    animation:mymove 3s infinite;
    transition: all 3s;
}

@keyframes mymove {
    0% {
        transform: translateX(100px);
    }
    100% {
        transform: translateX(-400px);
    }
}

.title_count {
    color: #fff;
    padding: 10px 16px;
    font-size: 16px;
}

.za-popup--center {
    width: 100% !important;
}

.za-marquee {
    width: 92%;
    margin: 0 auto;
    padding: 10px 0;
    border-radius: 8px;
    background-color: #fff;
}

.za-marquee__body > .za-marquee__content{
    width: 100%;
}

.not_count {
    color: #fff;
}

.rv-tabs__wrap {
    position: fixed;
    width: 100%;
    top: 0;
}