@font-face {
  font-family: "iconfont"; /* project id 2391557 */
  src: url("//at.alicdn.com/t/font_2391557_z0prn8i5guq.eot");
  src: url("//at.alicdn.com/t/font_2391557_z0prn8i5guq.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_2391557_z0prn8i5guq.woff2") format("woff2"),
    url("//at.alicdn.com/t/font_2391557_z0prn8i5guq.woff") format("woff"),
    url("//at.alicdn.com/t/font_2391557_z0prn8i5guq.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_2391557_z0prn8i5guq.svg#iconfont") format("svg");
}
