.Warp_top_info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &>.rec_num{
        color: red;
        font-size: 26px;
        padding-left: 10px;
        &>.rec_text{
            font-size: 12px;
            color: #000000;
            margin-left: 5px;
        }
    }
    &>.rec_btn{
        &>button{
            font-size: 12px;
            padding: 5px 10px;
            margin: 0 5px;
        }
    }
}
.rec_content{
    border: 1px solid;
    border-radius: 8px;
    padding: 5px;
    // height: 95vh;
    & img {
        width: 100% !important;
    }
}

.Test_Null {
    text-align: center;
    line-height: 5;
}

.Content_Item p {
    font-size: 15px;
}

.op_item {
    font-size: 14px;
    &>span{
        line-height: 1.5;
    }
}