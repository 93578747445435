.voucher_content_f {
    height: 100vh;
    background-color: #eeeeee;
}

.voucher_content {
    margin-top: 10px;
    border-bottom: 8px solid #eeeeee;
    background-color: #ffffff;
}

.voucher_warp {
    width: 95%;
    margin: 0 auto;
    border-radius: 10px;
    &>img{
        width: 100%;
        border-radius: 10px;
    }
}

.voucher_title {
    font-size: 18px;
    margin: 10px 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #cacaca;
}

.voucher_cell{
    padding: 10px;
    .voucher_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        padding: 5px 0;
        &>.voucher_text{
            color: #f96400;
        }
    }
}

.voucher_btn_cation {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    &>button{
        width: 50%;
        height: 30px;
        font-size: 19px;
        font-weight: bold;
        border: none;
        background-color: #ffffff;
    }
    &>.voucher_icon_btn{
        width: 1px;
        height: 30px;
        background-color: #d8d8d8;
    }
}

.voucher_step{
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
    padding-top: 10px;
    padding-bottom: 100px;
    background-color: #ffffff;
}

.step_item {
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
    overflow: hidden;
}

.left_icon {
    display: flex;
    justify-content: flex-start;
}

.link_cion {
    width: 3px;
    height: 100%;
    margin: 0 auto;
    background-color: #00bc71;
}

.read_icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #00bc71;
}