.page {
  min-height: 100vh;
  overflow: auto;
}
.page .content-with-padding {
  margin-bottom: 80px;
}
@keyframes pageLoading {
  from {
    transform: rotate(0);
  }
  12.5% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(90deg);
  }
  37.5% {
    transform: rotate(135deg);
  }
  50% {
    transform: rotate(180deg);
  }
  62.5% {
    transform: rotate(225deg);
  }
  75% {
    transform: rotate(270deg);
  }
  87.5% {
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.page .loading {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.page .loading span {
  font-size: 2em;
  color: #6c9bc3;
  animation-name: pageLoading;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: step-end;
}
.page .mask {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1001;
  min-height: 100vh;
  background-color: rgba(160, 160, 160, 0.3);
  user-select: none;
}

.za-tab-bar {
  overflow: unset !important;
}

.gift_center_icon {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;

  animation: Scale_anim 2s infinite;
  animation-direction:alternate;/*轮流反向播放动画。*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
}


@keyframes  Scale_anim
{
  0%{
  transform: scale(1);  /*开始为原始大小*/
  }
  25%{
      transform: scale(1.1); /*放大1.1倍*/
  }
  50%{
      transform: scale(1);
  }
  75%{
      transform: scale(1.1);
  }

}

@-webkit-keyframes  Scale_anim /*Safari and Chrome*/
{
  0%{
  transform: scale(1);  /*开始为原始大小*/
  }
  25%{
      transform: scale(1.1); /*放大1.1倍*/
  }
  50%{
      transform: scale(1);
  }
  75%{
      transform: scale(1.1);
  }
}