.photographic-plate-download-page {
}
.photographic-plate-download-page .with-border {
  width: 90%;
  margin: 15px auto;
}
.plate-button-row {
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}
