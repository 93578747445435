.photo-family-collection {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: url("https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3175270823,2828391229&fm=26&gp=0.jpg");
}
.photo-family-collection-tooltip {
  position: fixed;
  bottom: 10vw;
  width: 60%;
  margin-left: 20%;
  padding: 10px;
  left: 0;
  text-align: center;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.photo-family-collection-tooltip p {
  font-size: 0.6em;
  padding: 0;
  margin: 3px;
  line-height: 1.5;
}
.main-container {
}

.main-container .swiper-item {
  display: flex;
  height: 100vh;
  width: 100vw;
  /* justify-content: center; */
  margin-top: 20%;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.main-container .swiper-item span {
  position: absolute;
  margin-top: 10%;
  font-size: 0.8em;
  z-index: 999;
  top: calc((100vh - 120vw) / 2 - 23vw);
  color: #666;
}
.main-container .swiper-item img {
  width: 80vw;
  /* margin-bottom: 10vw;
  height: 120vw; */
  outline: 15px solid white;
  box-shadow: 5px 5px 15px #5f5f5f;
}

.warp_notice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  z-index: 10;
}

.warp_notice {
  height: 30px !important;
}

.message_text {
    position: fixed;
    bottom: 9%;
    right: 0px;
    left: 0px;
    margin: 0px auto;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    z-index: 11;
}

.photo-family-collection .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 41px !important;
}