.Agreement {
    min-height: 130vh;
    &>.Agreement_ID{
        text-align: left;
        font-size: 13px;
        color: rgb(138, 138, 138);
        margin: 5px;
    }
}

.vlaue_color {
    color: #8d8d8d;
}

.data_loading {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}