.ActivityRegistration {
    background-color: #ffb100;
    &>.ActivityRegistration_warp{
        padding: 10px;
        &>img {
            width: 100%;
            border-radius: 8px;
        }
    }
    &>.ActivityRegistration_Regist{
        &>.Regist_content{
            width: 85%;
            height: 300px;
            padding: 20px 0 40px 0;
            margin: 0 auto;
            margin-top: -50px;
            position: relative;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            box-shadow: 0px 2px 7px #909090;
            background-color: #fff;
            &>div>.Regist_item{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                font-size: 15px;
                padding: 0 20px;
                &>div>img{
                    width: 35px;
                    margin-right: 15px;
                    border-radius: 50%;
                }
            }
            &>.regist_btn{
                position: absolute;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
    &>div>.images_list{
        padding: 0 10px;
        &>img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &>.desc_info{
        font-size: 15px;
        text-align: center;
        color: rgb(177, 177, 177);
        margin-bottom: 130px;
    }
    &>.btn_bottom{
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        box-sizing: content-box;
        z-index: 10;
        &>.btn_left{
            display: block;
            width: 40%;
            color: #000;
            float: left;
            font-size: 15px;
            text-align: center;
            line-height: 4;
            border-top: 1px solid #eeeeee;
        }
        &>.btn_right{
            width: 60%;
            float: right;
            padding: 8px;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            background: #e54747;
            &>p{
                color: #fff;
                margin: 5px 0;
            }
        }
    }
    &>.Music_icon{
        position: fixed;
        bottom: 20%;
        right: 5%;
        width: 40px;
        z-index: 10;
        &>img {
            width: 100%;
            animation: music 5s linear infinite;
        }
    }
}

@keyframes music {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.popuo_Content {
    min-height: 200px;
    background-color: #fff;
    overflow-y: scroll;
    max-height: 100vh;
    padding-bottom: 50px;
}

.center_alert {
    width: 80%;
    background: #fff;
    margin: 0 auto;
    // height: 80vh;
    padding-bottom: 10px;
}

.qrm{
    width: 342px;
    height: 460px;
    border: 1px solid #ddd;
    position: relative;
    left: 50%;
    margin: 0 -170px 0 -170px;
    padding: 10px;
}
.img{
    width: 320px;
    height: 320px;
    text-align: center;
}
.img > img{
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
}
.info{
    width: 100%;
    height: 120px;
}
.title{
    width: 100%;
    height: 38px;
    font-size: 18px;
    font-family: serif;
    font-weight: 600;
    padding: 5px 8px;
}
.ewm{
    width: 100%;
    height: 80px;
    float: left;
}
.gzh{
    width: 60%;
    height: 100%;
    font-size: 13px;
    font-family: serif;
    padding: 8px 8px 5px 8px;
    color: #727272;
    float: left;
    line-height: 1.3;
}
.eph{
    width: 40%;
    height: 100%;
    float: left;
}
.eph > img{
    width: 80px;
    height: 80px;
    position: relative;
    left: 50%;
    margin: 0 -40px 0 -40px;
}

.theRegistrationContent{
    padding: 10px 0;
    border-bottom: 12px solid #eee;
    &>.atTheBottomOfTheInstructions {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        align-items: center;
        font-size: 14px;
        color: #909090;
        // padding: 10px 0;
        &>.restUpdate{
            color: hsl(156deg 100% 33%);
        }
    }
}

.lable_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 10px;
    font-size: 15px;
    &>.lable_name{
        width: 90px;
        color: #000;
    }
    &>.lable_value{
        color: #a0a0a0;
        &>span{
            color: #000;
            margin-left: 10px;
        }
    }
}