.titleRead {
    display: flex;
    justify-content: center;
    align-items: center;
    &>span{
        font-size: 12px;
        padding: 0 5px;
    }
}

.comment {
    &>.comment_Title{
        font-size: 20px;
    }
    &>.comment_Description{
        font-size: 16px;
    }
}