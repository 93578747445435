.za-mask {
    background: #000000cc !important;
}

.za-notice-bar {
    height: 35px !important;
    line-height: 35px;
}

.za-popup__wrapper {
    padding: 0 !important;
}