.custom-control {
  min-width: 100vw;
  text-align: center;
}
.tag-container {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.6em;
}
.tag-container .tag {
  padding: 1px 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 9px;
  border-radius: 998px;
  border: 1px solid black;
}
.modal-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
}

.modal-buttons button {
  width: 100%;
  margin: 0 10px;
}

.navbar {
  background-color: rgb(59, 59, 63) !important;
  color: white !important;
}

.tooltip {
  margin: 30px 30px 5px 30px;
  border-radius: 8px;
  background-color: rgb(238, 238, 238);
  padding: 10px;
  font-size: 0.6em;
}

.tooltip p {
  margin: 0;
  color: rgb(171, 171, 171);
  line-height: 1.5;
}

.divider {
  height: 10px;
  width: 100vw;
  background-color: #e2e2e2;
}

.photographic-plate-download-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photographic-plate-download-modal .title {
  font-size: 1em;
  width: 100%;
  min-height: 4em;
  margin-bottom: 20px;
  border-radius: 15px;
  background-color: rgb(250, 203, 85);
  line-height: 4em;
  text-align: center;
}

.photographic-plate-download-modal p {
  text-wrap: normal;
  width: 100%;
  font-size: 0.9em;
  margin: 5px;
  padding: 0;
}

.photographic-plate-download-modal .button {
  margin-top: 20px;
  min-width: 8em;
}

.bangbangtang-tabs .panel {
  font-size: 0.7em !important;
}
