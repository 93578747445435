.customer-query-preorder-clothe-list-widget {
  width: 100%;
  button {
    border: none;
    font-size: 0.9em;
    background: none;
    border-left: #12C287 solid 1px;
    border-radius: 0;
    color: #12C287;
    line-height: 1;
  }
  .customer-query-preorder-clothe-list--widget-card {
    padding-left: 25px;
  }
  .card {
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    padding: 15px;
    font-size: 0.7em;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      line-height: 1.5;
      .col{
        display: flex;flex-direction: column;
        margin-right: 40px;

      }
    }
  }
}
