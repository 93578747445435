.bangbangtang-tabs {
  background: white;
}
.reset-button {
  position: fixed;
  bottom: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 40vw;
  }
}
.search-bar {
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
  justify-content: center;
  input {
    background: #f2f2f2;
    font-size: 14px;
    border-radius: 6px;
    height: 33px;
    padding: 0 6px;
    flex-grow: 1;
  }
  button {
    height: 44px;
    border-radius: 0;
    border: none;
    font-size: 14px;
    color: #00bc71;
  }
}
.employee-query-preorder-search-button {
  position: fixed;
  bottom: 1em;
  width: 40vw;
  left: 30vw;
}
.employee-query-preorder-clothe-list-card {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f3f3f3;
  padding: 8px;
  .head {
    width: 20%;
    flex-grow: 0;
  }
  .avatar {
    display: flex;
    align-items: center;
    align-self: center;
    margin: 5px;
    img {
      width: 3em;
    }
  }
  .row {
    display: flex;
    width: 50%;
    line-height: 1.7;
  }
  .img-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    img {
      max-height: 4em;
      margin: 0 5px;
    }
  }
  .content {
    flex-grow: 1;
    height: 100%;
    padding: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.7em;
    .tag {
      color: #909090;
    }
  }
  .time {
    font-size: 11px;
    color: #bcbcbc;
  }
}
