.Search_warp {
  position: relative;
  padding: 20px 10px 5px 10px;
  & > .Search_input {
    width: 76%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    border-radius: 25px;
    border: 1px solid;
    display: block;
  }
  & > .Search_icon {
    position: absolute;
    right: 17%;
    bottom: 10px;
    width: 21px;
    height: 21px;
  }
}

.Test_content {
  padding: 10px;
  & > .Test_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    // border: 1px solid;
    box-shadow: -2px 2px 20px #dfdfdf;
    border-radius: 8px;
    margin-bottom: 10px;
    & > .Test_info {
      font-size: 13px;
      & > div {
        padding: 2px 0;
      }
    }
    & > .Test_num {
      font-size: 15px;
      border: 1px solid;
      padding: 5px 10px;
      text-align: center;
      border-radius: 8px;
      & > div {
        margin: 0;
      }
      & > .Test_max_text {
        font-size: 12px;
        padding: 3px 0;
      }
    }
    & > .Test_not_pass {
      color: #ffffff;
      background-color: red;
    }
  }
}

.Test_info_bottom {
  position: fixed;
  bottom: 5%;
  width: 80%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 5px 4px 20px #cdcdcd;
  & > div {
    font-size: 13px;
  }
}

.Test_basics {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: #ffffff;
  overflow-y: scroll;
  & > .Test_btn_examination {
    position: fixed;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    color: #ffffff;
    font-size: 16px;
    box-shadow: -2px 2px 20px #dfdfdf;
    // border-radius: 8px;
    button {
      width: 34%;
      line-height: 3;
      background: white;
      border: none;
    }
    &>.Test_content{
        height: 100vh;
        overflow-y: scroll;
        padding-bottom: 100px;
        & p{
            font-size: 14px;
        }
    }
  }
}

.Test_Text_null {
  text-align: center;
  line-height: 5;
  font-size: 14px;
}
