.customer-query-preorder-clothe-card {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  .label {
    font-size: 0.7em;
    width: 50%;
    line-height: 2;
    span:last-child {
      color: #636363;
    }
  }
  .content {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .image-row {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    padding: 5px;
  }
  img {
    height: 4em;
    margin-right: 0.5em;
  }
  .time {
    display: flex;
    color: #909090;
    flex-direction: column;
    justify-content: space-around;
    text-align: end;
    width: 30%;
    height: 100%;
  }
}
.customer-query-preorder-clothe-list-card {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f3f3f3;
  padding: 8px;
  .head {
    width: 20%;
    flex-grow: 0;
  }
  .content {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 0.7em;
      margin-bottom: 10px;
    }
    .tag {
      color: #909090;
      font-size: 0.7em;
    }
  }
  .time {
    font-size: 11px;
    color: #bcbcbc;
  }
}
