.content {
  font-size: 12px;
  padding-top: 45px;
}

.item_title {
  font-size: 12px;
}

.desc_info {
  font-size: 12px;
  text-align: center;
}

.Cancel_btn {
  text-align: center;
  line-height: 1;
  padding: 4px 7px;
  font-size: 12px;
  border-radius: 4px;
  color: #fff;
  background: #00bc70;
}

.schedule_content_ {
  padding: 10px;
  font-size: 12px;
  background: #fff;
  max-height: 90vh;

  &>.schedule_content_close {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>.close_btn {
      color: #00bc70;
      border: 1px solid #00bc70;
      border-radius: 4px;
      padding: 3px 5px;
      line-height: 1;
    }
  }
}