.information {
    overflow: hidden;
    padding: 20px 0;
    border-bottom: 10px solid #eeeeee;
}

.information_warp{
    display: flex;
    justify-content: space-between;
    &>.information_warp_ava{
        width: 30%;
        &>img{
            width: 50%;
            border-radius: 50%;
            display: block;
            margin: 0 auto;
        }
        p{
            text-align: center;
            margin:14px 0;
            color:#12C287;
            font-size:16px;
            font-weight:500;
        }
    }
    &>.information_warp_info{
        width: 35%;
        color: #222222;
        font-size: 18px;
        padding-right: 20px;
        &>p{
            color: #343434;
            margin: 5px 0;
            text-align: right;
        }
    }
}

.btn_sw{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-size: 16px;
    padding-right: 20px;
    &>p{
        width: 100%;
        text-align: right;
        color: #00bc71;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5px;
    }
    &>button{
        width: 100px;
    }
}