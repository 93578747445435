.employee-clothe-save-modal-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 45%;
  }
}
.employee-preorder-clothe-popup {
  background: white;
  padding-top: 7px;
  padding-bottom: 7px;

  .submit-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    button {
      width: 25vw;
    }
  }
  .tag-container {
    display: flex;
    // flex-direction: column;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .tag-row {
    padding: 3px 15px;
    width: 100%;
    display: flex;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      min-width: 3em;
      max-width: 3em;
      flex-grow: 0;
    }
    .button-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 1;
      align-items: flex-start;
    }
    .button {
      color: black;
      border: #19c286 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      border-radius: 8888px;
      min-width: 60px;
      padding: 0 10px;
      height: 30px;
    }
    .button.active {
      background: #19c286;
      color: white;
    }
  }

}
$pic_width: 45vw;
.employee-preorder-clothe {
  display: flex;
  flex-flow: row;
  background: #eeeeee;
  .active-photo {
    border: 2px solid #00bc71;
  }
  .first_col {
    width: $pic_width;
    margin-left: (100vw-$pic_width * 2)/3;
    margin-right: (100vw-$pic_width * 2)/3/2;
  }

  .second_col {
    width: $pic_width;
    margin-right: (100vw-$pic_width * 2)/3;
    margin-left: (100vw-$pic_width * 2)/3/2;
  }
  img {
    margin-top: (100vw-$pic_width * 2)/3;
    max-width: 100%;
  }
}
.employee-preorder-clothe-button-row {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 2em;
  bottom: 1em;

  .button {
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2em;
    font-size: 0.8em;
    border-radius: 100%;
    margin-bottom: 1em;
  }
}
.qr-code {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
