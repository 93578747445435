.Express {
    padding: 15px 15px 15px 60px;
    &>.Express_item{
        position: relative;
        color: #8d8d8d;
        line-height: 1.5;   
        font-size: 14px;
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid #d1d1d1;
        // margin-bottom: 15px;
        &>.time_link{
            position: absolute;
            top: 0;
            left: -40px;
            width: 2px;
            height: 101%;
            background-color: #00bc70;
            &>.node_time{
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #00bc70;
                top: 45%;
                left: -4px;
            }
        }
    }
    &>.Express_item_color{
        color: #00bc70;
    }
    &>.express_name{
        font-size: 17px;
        margin-bottom: 15px;
    }
}

.warp_top {
    background: #eee;
    font-size: 15px;
    padding: 10px;
}

.logo {
    width: 20px;
    margin-right: 10px;
    border-radius: 50%;
}

.express_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}


.time_link_ov {
    position: absolute;
    left: -47px;
    top: 7px;
    font-size: 19px;
    font-weight: bold;
}

.Tipes_text {
    text-align: center;
    margin-top: 50px;
    font-size: 13px;
    color: #969696;
}