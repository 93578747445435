.container{
  background-color: #f5f5f5;
  min-height:100vh;
  padding-bottom:50px;

  :global{
    .detai-container{
      background-color: #fff;
      margin-bottom:10px;

      .section{
        padding:15px;
        line-height:20px;
        .k{
          float:left;
          font-size:15px;
          color:#464646;
        }
        .v{
          margin-left:100px;
          font-size:15px;
          color:#464646;
        }
        &::after{
          content:'';
          display:block;
          clear:both;
        }
      }
    }   
    
    .photo-list{
      background-color: #fff;
      padding:15px 15px 0;

      .picture-item{
        float:left; 
        width:70px;
        height:70px;
        margin:0 15px 15px 0; 
        overflow: hidden;

        img{
          width:100%;
          height:100%;
          object-fit: cover;
        }
      }
      &::after{
        content: '';
        display: block;
        clear: both;
      }
    }

    .revoke{
      position:fixed;
      width:100%;
      bottom:0;
      text-align: center;
      height:50px;
      padding:7px 0;
      background-color: #fff;
    }
  }
}


.detailModal {
  :global{
    .journal-detail {
      height: 285px;
      overflow-y: auto;
      word-break: break-all;                                                                                                                                                                                                                     
    }
  
    .t-c {
      text-align: center;
    }
  
    .za-modal__footer {
      padding: 0;
      border-top: 1px solid #e5e5e5;
  
      .sure {
        color: #00bc71
      }
    }
  
    .confirmBtns {
      display: flex;
      width: 100%;
  
      .btn {
        flex: 1;
        font-size: 15px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        color: #666;
  
        &:first-child {
          border-right: 1px solid #e5e5e5
        }
  
        &:last-child {
          color: #00bc71;
        }
      }
    }
  }
}