.content {
  padding: 10px;
  font-size: 15px;
  &>div{
    padding: 3px 0;
    &>span{
      color: #00bc70;
    }
  }
}

.rulesOfThePicture{
  &>img{
    width: 100%;
  }
}

.withdrawalStyles{
  position: fixed;
  bottom: 6%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  font-size: 15px;
  padding: 10px 0;
  color: #fff;
  border-radius: 25px;
  background: #00bc70;
}

.theCommissionSubsidiaryStyles {
  position: fixed;
  bottom: 15%;
  right: 10px;
  text-align: center;
  width: 45px;
  height: 45px;
  line-height: 1.3;
  padding: 8px;
  color: #00bc70;
  border-radius: 50%;
  font-size: 12px;
  box-shadow: 3px 3px 8px 0px #d1d1d1
}

.theWithdrawalOfSubsidiaryStyles {
  position: fixed;
  bottom: 22%;
  right: 10px;
  text-align: center;
  width: 45px;
  height: 45px;
  line-height: 1.3;
  padding: 8px;
  color: #00bc70;
  border-radius: 50%;
  font-size: 12px;
  box-shadow: 3px 3px 8px 0px #d1d1d1
}