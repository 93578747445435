.employee-preorder-card {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e2e2e2;
  background: white;
  flex-direction: column;
  .action-row {
    display: flex;
    justify-content: flex-end;
    button {
      font-size: 0.6em;
      border-radius: 999px;
      line-height: 1;
      height: 30px;
    }
  }
  .button-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .open {
    display: block !important;
  }
  .close {
    display: none !important;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    .image-row {
      display: flex;
      flex-direction: row;
      border-radius: 0;
      padding: 15px;
      border-top: 1px solid #c3c3c3;
      border-bottom: 1px solid #c3c3c3;
      margin-bottom: -1px;
      img {
        max-width: 30%;
        width: 40%;
      }
      p {
        padding: 15px;
        font-size: 0.7em;
      }
    }
  }

  .info-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.7em;
    .info-row {
      width: 50%;
      line-height: 1.8;
      div {
        display: inline-block;
      }
      .value {
        padding-left: 8px;
      }
    }
  }
}
