.contentDistribution {
  position: relative;
  padding: 10px;

  &>.item {
    display: flex;
    padding: 10px 0;
    // border: 1px solid #626262;
    // border-radius: 7px;
    margin-bottom: 10px;
    border-bottom: 1px solid #d1d1d1;
    // box-shadow: 3px 3px 3px #eee;
    padding-bottom: 5px;

    &>.images_left {
      width: 125px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 7px;

      &>img {
        // width: 100%;
        height: 100%;
        border-radius: 7px;
      }
    }

    &>.name {
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}

.title_text {
  color: #2a2a2a;
  // font-weight: bold;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.end_time {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desc_text {
  margin: 5px 0;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #a3a3a3;
}

.logo_settlement {
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.timeSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;

  &>div {
    width: 50%;
    padding: 10px;
    font-size: 13px;
    text-align: center;
    // border: 1px solid;
    line-height: 1;
  }
}

.item_set {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  &>.user_ava {
    width: 70px;

    &>img {
      width: 100%;
    }
  }

  &>.user_info {
    width: 260px;
    font-size: 15px;

    &>div {
      margin: 5px 0;
    }
  }
}

.switc_my {
  font-size: 15px;
  padding: 10px 0;
}

.total {
  padding: 0 !important;
  width: 25px !important;
  height: 25px;
  line-height: 25px !important;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  background-color: red;
}

.left_count_size {
  display: flex;
  align-items: center;
}

.list_data {
  height: 80vh;
  overflow-y: scroll;
  // background: red;
}

.back_my_user {
  position: fixed;
  width: 30px;
  height: 30px;
  right: 5%;
  bottom: 5%;
}

.btn_href {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.mobile_min {
  display: flex;
  align-items: center;

  &>img {
    width: 20px;
    margin-left: 5px;
  }
}