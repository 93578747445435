.NoticeBar_warp {
    background-color: #fff7cb;
}

.film_download {
    min-height: 100vh;
    padding-bottom: 60px;
    background-color: #eeeeee;
}

.swipe_action {
    margin-top: 10px;
}

.filmDownload_btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filmDownload_btn > button {
    width: 50%;
}

.moda_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.moda_btn > button {
    width: 47%;
    height: 35px;
}

.delete_film_button {
    width: 48%;
    height: 40px;
}

.help_content {
    max-height: 400px;
    word-break: break-all;
}

.button-row-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-row-down > button{
    width: 48%;
    height: 40px;
}

.message_null {
    margin-top: 62px;
    text-align: center;
    padding: 0 20px;
    word-break: break-all;
    font-size: 15px;
    color: #585858;
}

.fixBgP{
    position: fixed;
    top: 0;
    background: #000000c9;
    z-index: 10;
    left: 0;
    right: 0;
}

.fixClose {
    position: fixed;
    top: 5%;
    right: 5%;
    width: 40px;
    z-index: 11;
}

.btn_down {
    position: fixed;
    bottom: 5%;
    width: 130px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 11;
    text-align: center;
    padding: 10px 15px;
    color: rgb(216, 216, 216);
    font-size: 14px;
    border-radius: 7px;
    /* background: #00bc70; */
}

.wx_tips {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333333;
    z-index: 105;
}

.wx_tips>img{
    width: 100%;
}

.box-content {
    padding-left: 20px;
}

.box-description-text {
    font-size: 12px;
}

.fixBgP .swiper-pagination-bullet{
    width: 15px !important;
    height: 15px !important;
    opacity: 1;
}


.fixBgP .swiper-pagination-bullet-active {
    background-color: #ffffff !important;
}

.fixBgP .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 80px !important;
}

.fixBgP .swiper-pagination-fraction {
    color: #ffffff !important;
}

.select_text {
    position: fixed !important;
    bottom: 13% !important;
    color: #ffffff;
    width: 20%;
    text-align: center;
}

.text_tag {
    padding: 10px 0;
}

.text_tipes {
    font-size: 12px;
}