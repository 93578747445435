.btn_fix {
    position: fixed;
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 5%;
}

.DatetimePicker_v {
    position: fixed;
    width: 100%;
    bottom: 0;
    border-top: 1px solid #eee;
}

.tipes_time{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content_time {
    // padding: 0 93px;
    &>.time_title{
        padding: 0 90px;
        color: #adadad;
        margin-top: 100px;
        font-size: 16px;
        text-align: left;
    }
    &>.time_count{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 15px 0;
        &>.t_X{
            font-size: 55px;
            font-weight: bold;
            line-height: 0.7;
        }
        &>.t_s{
            font-size: 17px;
            margin: 0 10px;
        }
    }
}

.update_time{
    color: #00bc70;
    padding: 3px 15px;
    border-radius: 8px;
    border: 2px solid #00bc70;
}