.photographic-plate-download-widget {
  width: 100%;
}
.photographic-plate-download-widget ._content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.photographic-plate-download-modal .url {
  font-weight: bold;
}
.photographic-plate-download-widget .page-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 5px;
  align-items: center;
}

.photo-family-collection-download-modal {
}
.photographic-plate-download-modal {
  min-height: 40vh;
}
