
.list_Content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    // margin: 10px;
    padding-bottom: 60px;
    background-color: rgb(219, 219, 219);
    &>.list_item{
        width: 50%;
        // background-color: salmon;
        &>div{
            margin: 8px;
        }
        &>div>div{
            position: relative;
            font-size: 0;
            border: 5px solid #fff;
            overflow: hidden;
        }
        &>div>div>img{
            width: 100%;
        }
        &>.images_content{
            position: relative;
            &>.add_images{
                position: absolute;
                bottom: -7px;
                right: -7px;
                border: none;
                width: 30px;
            }
        }
    }
    &>.more_text{
        width: 100%;
        text-align: center;
        // background: #fff;
        padding: 10px 0 160px 0;
        font-size: 12px;
        color: #afafaf;
    }
}

.watermarkText {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #878686;
    bottom: 0;
    font-size: 12px;
    line-height: 2;
    opacity: .8;
}

.Settlement{
    position: fixed;
    width: 95%;
    height: 60px;
    left: 0;
    right: 0;
    bottom: 1%;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 5px 10px;
    z-index: 10;
    box-shadow: 0px 1px 6px #919191;
    // overflow: hidden;
    border-top: 1px solid #eee;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    &>.Settlement_top_tipe{
        position: absolute;
        width: 90%;
        top: -35px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 5px 0;
        font-size: 15px;
        text-align: center;
        color: rgb(255,167,79);
        background: rgb(255,242,233);
    }
    &>.Settlement_left{
        width: 20%;
        background: rgb(255,241,241);
        padding: 5px;
        & .Settlement_left_select{
            font-size: 25px;
        }
        & .Settlement_left_all {
            font-size: 13px;
        }
        &>.Settlement_left_text{
            font-size: 14px;
            padding-top: 5px;
        }
    }
    &>.Settlement_center{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 55%;
        height: 70px;
        padding: 0 10px;
        & .Settlement_center_price{
            font-size: 15px;
            color: rgb(240,112,61);
        }
        & .Settlement_center_price_info {
            font-size: 13px;
            &>div{
                margin: 5px 0;
            }
        }
    }
    &>.Settlement_right_text{
        width: 25%;
        text-align: center;
        font-size: 15px;
        padding: 0 10px;
        height: 60px;
        line-height: 60px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        background-image: linear-gradient(to right, rgb(252,235,122) , rgb(249,218,84));
    }
}

.action_color{
    color: rgb(240,112,61);
}

.service {
    display: block;
    position: fixed;
    bottom: 27%;
    right: 5%;
    width: 50px;
    height: 50px;
    // padding: 5px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    &>img{
        width: 100%;
    }
}

.PackageBtn {
    position: fixed;
    right: 5%;
    bottom: 20%;
    width: 50px;
    height: 50px;
    font-weight: bold;
    border: 1px solid #eeeeee;
    background: #fff;
    border-radius: 50%;


    font-size: 13px;
    text-align: center;
    line-height: 1.2;
    padding: 9px;
    color: #fff;
    background-color: #00bc70;

    &>img{
        width: 100%;
    }
}

.NoticeBar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Popup_box {
    position: relative;
}

.select_baby>div{
    padding: 10px 0;
}

.popupState_content {
    background: #fff;
    padding: 20px;
    height: 90vh;
    overflow-y: scroll;
    padding-top: 80px;
    &>.close_btn{
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;
        right: 0;
        margin: 0 auto;
        padding: 10px;
    }
    &>.item{
        position: relative;
        border-radius: 10px;
        padding: 15px;
        min-height: 120px;
        color: #fff;
        margin-bottom: 20px;
        background-image: linear-gradient(to right, rgb(255,140,202) , rgb(255,168,191));
        &>h4{
            margin: 0;
            font-size: 18px;
        }
        &>p{
            width: 80%;
            margin: 5px 0;
            font-size: 14px;
        }
        &>.select_action{
            position: absolute;
            top: 27%;
            right: 15px;
            width: 60px;
            height: 60px;
            color: rgb(201,114,84);
            background: rgb(255,218,108);
            text-align: center;
            padding: 10px;
            line-height: 19px;
            font-size: 17px;
            border-radius: 8px;
        }
    }
}

.acton {
    color: #00bc70 !important;
    border-color: #00bc70 !important;
}


.packPopup{
    // min-width: 1000px;
    display: flex;
    justify-content: flex-start;
    background: #fff;
    padding: 20px;
    min-height: 30vh;
    &>.item_row{
        position: relative;
        margin: 0 15px;
        // background: red;
        &>img{
            height: 150px;
        }
        &>.item_desc{
            text-align: center;
            &>.tag{
                width: 100px;
                margin: 0 auto;
                font-size: 14px;
                border-radius: 25px;
                padding: 2px 5px;
                text-align: center;
                margin-top: 5px;
                border: 1px solid #eeeeee;
            }
        }
        &>.close{
            position: absolute;
            top: -7px;
            right: -7px;
            width: 25px;
            height: 25px;
        }
    }
}

.PopupSwiper {
    padding: 0 !important;
}

.null_content {
    position: relative;
    width: 95%;
    color: #fff;
    min-height: 100px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 10px;
    margin-top: 80px;
    background-image: linear-gradient(to right, rgb(122,202,230) , rgb(185,158,217));
    &>p{
        font-size: 13px;
    }
    &>.nul_warp_top{
        position: absolute;
        top: -30px;
        left: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>img{
            width: 30px;
            margin-right: 15px;
        }
        &>span{
            color: rgb(161,123,204);
            font-weight: bold;
            font-size: 17px;
        }
    }
}

.closeIcon{
    position: absolute;
    top: -77px;
    right: 10px;
    width: 40px !important;
    outline: none !important;
    box-shadow: none !important;
}

.tipe_text {
    position: absolute;
    top: -14px;
    right: 0;
    color: #fff;
    text-align: center;
    left: 0;
    right: 0;
    font-size: 12px;
    margin: 0 auto;
}

.warp_images {
    position: relative;
    width: 350px;
    height: 82vh;
    margin: 0 auto;
    // padding: 20px;
    // background: #fff;
    &>.warp_btn{
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 15px;
        &>.null_text {
            color: #fff;
            padding-bottom: 20px;
        }
        &>div>div{
            text-align: center;
            color: #fff;
        }
        & .tag_name{
            // display: flex;
            align-items: center;
            // color: #000;
            padding: 3px 7px;
            border-radius: 25px;
            color: #fff;
            border: 1px solid #fff;
            // background: rgb(246,198,145);
            &>img{
                width: 20px;
                outline: none;
                box-shadow: none;
            }
        }
        & .tag_name_select {
            background: rgb(235, 127, 13);
        }
    }
    &>.images_content{
        position: relative;
        height: 95%;
        padding: 0 15px;
        &>.images_content_img{
            width: 100%;
            margin-top: 20px;
            border: 1px solid #c7c7c7;
        }
        &>.yesIcon{
            position: absolute;
            right: 0;
            width: 50px;
            border: none;
            bottom: 0;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

.content_warp {
    &>div>img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}

.selection_pay {
    padding: 0 10px;
    overflow: hidden;
    // background: #ececec;
    &>.selection_warp{
        margin-top: 20px;
        &>p{
            display: flex;
            align-items: center;
            font-size: 20px;
            // padding-bottom: 20px;
            // border-bottom: 1px dashed #919191;
            &>img{
                width: 38px;
                margin-right: 10px;
            }
        }
        &>.selection_text{
            font-size: 14px;
            line-height: 1.5;
        }
    }
    &>.selection_list{
        background: rgb(255, 255, 255);
        background-image: linear-gradient(to right, #7acae6, #b99ed9);
        padding: 10px;
        margin: 15px 0 100px 0;
        line-height: 2;
        font-size: 15px;
        border-radius: 8px;
        box-shadow: 0px 1px 7px #d2d2d2;
        // &>.selection_list_warp{
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
        // }
        &>.selection_list_images{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 20px;
            padding-bottom: 20px;
            max-height: 50vh;
            overflow-y: scroll;
            &>div{
                width: 80px;
                height: 130px;
                margin: 8px;
                // background-color: red;
            }
            &>div>div{
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                font-size: 14px;
                text-align: center;
            }
            &>div>div>img{
                width: 100%;
                // height: 50px;
                // margin: 8px;
                // border-radius: 10px;
            }
        }
        &>.selection_price>div{
            display: flex;
            justify-content: end;
            align-items: center;
            font-size: 14px;
            padding-top: 10px;
            line-height: 1.5;
            // border-bottom: 1px solid #eee;
        }
    }
    &>.btn_sub{
        position: fixed;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 5%;
        padding: 10px;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        border-radius: 25px;
        background: rgb(247,195,36);
        box-shadow: 1px -1px 8px #ccc;
    }
}

.tipe_content {
    position: relative;
    // width: 90%;
    // height: 60vh;
    margin: 0 auto;
    // background-color: #eee;
    background: #fff;
    // overflow: hidden;
    // overflow-y: scroll;
    border-radius: 5px;
    padding: 5px 10px;
    &>.btn_text{
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
    }
    &>img{
        position: absolute;
        right: -41px;
        top: -58px;
        width: 45px;
        height: 45px;
    }
}

.select_tag_text {
    position: absolute;
    top: 6px;
    left: 6px;
    color: #00bc70;
    font-size: 14px;
    font-weight: bold;
}

.toHelpGuide {
    position: fixed;
    right: 5%;
    background: #00bc71;
    z-index: 10;
    border-radius: 50%;
    width: 53px;
    height: 53px;
    bottom: 34%;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 53px;
    border: 1px solid #eeeeee;
}

.accord {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}


.card_item {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
    border-radius: 8px;
    padding: 10px;
    background-color: #ffffff;

    &>.card_img {
        width: 30%;
        height: 100%;
    }

    &>.card_info {
        position: relative;
        width: 65%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>.botm_text {
            position: absolute;
            bottom: 0;
            width: 100%;
            font-size: 11px;
        }
    }

    &>.tips_text {
        position: absolute;
        right: 5%;
        top: 40%;
        color: #b1b1b1;
        padding: 5px 15px;
        border: 1px solid #b1b1b1;
        border-radius: 5px;
        transform: rotateZ(-31deg);
    }
}