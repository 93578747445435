.index {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}
.widget {
  margin-top: 30px;
}

.Making_Arrangement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Making_Arrangement > div {
  width: 50%;
}
