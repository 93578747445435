.container {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 150px;

  :global {
    .za-cell:after {
      left: 0;
    }

    .related {
      background-color: #fff;
    }

    .za-collapse-item__content__inner {
      padding: 0;
    }

    .za-checkbox__inner {
      border-radius: 50%;
    }

    .za-checkbox__widget {
      position: absolute;
      right: 16px;
    }

    .related-list .za-checkbox__text{
      color:#00bc71;
      font-weight:bold;
    }

    .reform_images {
      background-color: #fff;
      font-size: 15px;
      padding: 15px 16px 0;
      border-top: 1px solid #dcdcdc;
    }

    .photo-list {
      background-color: #fff;
      padding: 15px 15px 0;

      .picture-item {
        float: left;
        width: 70px;
        height: 70px;
        margin: 0 15px 15px 0;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .delete-btn {
          position: absolute;
          top: -10px;
          right: -10px;
          color: rgba(0, 0, 0, .5);
          border-radius: 50%;
          z-index: 5;
        }
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .file-picker-btn {
      width: 70px;
      height: 70px;
      float: left;
      border: 1px dotted #aaaaaa;
      text-align: center;
      margin-bottom: 15px;
      border-radius: 4px;

      .za-icon {
        line-height: 70px;
      }

      .needsclick {
        width: 68px;
        height: 68px;
      }
    }

    .reform .za-checkbox__widget {
      right: 0;
      top: -9px;
    }

    .foot-option {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      height: 50px;
      box-sizing: border-box;
      border-top: 1px solid #dcdcdc;

      .button {
        text-align: center;
        line-height: 50px;
        font-size: 15px;
        color: #333;
      }
    }
  }
}

.detailModal {
  :global {
    .journal-detail {
      height: 285px;
      overflow-y: auto;
      word-break: break-all;
    }

    .t-c {
      text-align: center;
    }

    .za-modal__footer {
      padding: 0;
      border-top: 1px solid #e5e5e5;

      .sure {
        color: #00bc71
      }
    }

    .confirmBtns {
      display: flex;
      width: 100%;

      .btn {
        flex: 1;
        font-size: 15px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        color: #666;

        &:first-child {
          border-right: 1px solid #e5e5e5
        }

        &:last-child {
          color: #00bc71;
        }
      }
    }
  }
}

.selectProgressMan {
  :global{
    .title {
      text-align: center;
      color: #464646;
      font-weight: bold;
      font-size: 17px;
    }
  
    .select-form {
      height: 270px;
      overflow-y: auto;
    }
  }
}

.progressManBtns {
  display: flex;
  width: 100%;
  text-align: center;

  :global{
    .btn {
      flex: 1;
    }
  }
}