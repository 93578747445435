.clothing_box {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    &>.clothing_box_left{
        // width: 48%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex: 1;
        &>img{
            // width: 100%;
            width: 48%;
            margin-bottom: 10px;
        }
    }
    &>.clothing_box_right{
        width: 48%;
        &>img{
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.input_btn_form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    & button{
        width: 100px;
    }
}

.input_form input {
    background: #eeeeee;
    height: 40px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.click_all {
    color: #999999;
    font-size: 13px;
    line-height: 3;
    text-align: center;
}