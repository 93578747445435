.register {
  width: 100%;
}
.register .register-header {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3;
  font-size: 0.9em;
  width: 100%;
}
.register .register-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.register .register-content .avatar {
  margin-top: 50px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-color: #6c9bc3;
}

.register .register-content .username {
  margin-top: 15px;
  font-size: 1em;
  letter-spacing: 3px;
  margin-bottom: 50px;
}
.register .register-content .input-row {
  width: 90%;
  position: relative;
  margin-top: 20px;
}
.register .input-row input {
  border: none;
  line-height: 1.5;
  width: 100%;
  border-bottom: 2px solid #eee;
  padding-left: 50px;
}

.register .input-row .phone-input {
  font-size: 0.8em;
  height: 40px;
}

.register .input-row .iconfont {
  font-size: 25px;
  text-align: center;
  width: 50px;
  color: #6f9bbe !important;
  font-weight: 550;
  position: absolute;
  left: 0;
  top: 15%;
}

.register .input-row .more {
  position: absolute;
  right: 3%;
  top: 25%;
  font-size: 16px;
  color: #6c9bc3;
}
.register .input-row .disabled {
  color: #a0a0a0;
}
.register .checkbox-row {
  margin-top: 8px;
  font-size: 0.6em;
  line-height: 3;
  color: #6f9bbe;
  align-self: flex-start;
  padding-left: 30px;
}

.register .checkbox-row .checkbox {
  border-radius: 3px;
  margin-right: 15px;
  border: 1px solid #709cc1;
}
.register .button {
  margin-top: 20px;
  line-height: 3;
  background-color: #709cc1;
  font-size: 0.8em;
  color: white;
  width: 90%;
  border-radius: 5px;
  text-align: center;
}
.register .disabled-button {
  background-color: #c1c1c1;
}

.iHaveRegistered {
  margin-top: 10px;
}

.crazyInTheLoad {
  text-align: center;
    font-size: 14px;
    margin-top: 30%;
}