.info-report-widget {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info-report-widget .textarea {
  color: #000;
  padding: 10px;
  margin-top: 10px;
  width: 90%;
  border-radius: 8px;
  font-size: 15px;
  min-height: 5em;
}

.info-report-widget .textarea::-webkit-input-placeholder {
  color: rgb(216, 216, 216);
}

.info-report-widget .button {
  width: 60%;
  margin-top: 8px;
}

.info-report-widget .button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.info-report-widget .button-row .button {
  width: 40vw;
  margin-left: 15px;
  margin-right: 15px;
}
