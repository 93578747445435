.warp_Title{
    text-align: center;
    line-height: 2;
    padding: 5px 0;
    font-size: 18px;
    background: #ffffff;
}

.body_content {
    height: 90vh;
    margin-top: 20px;
    background: #ffffff;
    &>.body_content_title{
        width: 90%;
        margin: 0 auto;
        line-height: 2;
        padding-top: 10px;
    }
    &>.input_text{
        width: 90%;
        margin: 0 auto;
        &>.input_title{
            margin-top: 20px;
            font-weight: bold;
        }
        &>.input_textarea {
            width: 100%;
            margin-top: 10px;
            border-radius: 4px;
            resize: none;
            height: 100px;
            padding: 5px;
        }  
        &>.appointment_now{
            color: #ffffff;
            width: 100%;
            line-height: 2.5;
            margin-top: 20px;
            border: none;
            border-radius: 5px;
            background: #5edcdc;
        }
    }
}

.tel_Service {
    display: block;
    position: fixed;
    width: 60%;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 1px solid #000000;
    border-radius: 4px;
    color: #000000;
    padding: 10px 0;
    text-align: center;
}