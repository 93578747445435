.Test_Close{
    line-height: 3;
    margin-top: 120px;
    &>div{
        width: 100%;
    }
    &>.Test_Close_tot{
        text-align: center;
        font-size: 30px;
    }
    &>.Test_Close_btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        &>button{
            width: 200px;
            font-size: 16px;
            padding: 10px 0;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
}