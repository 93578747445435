.confirm_Page {
    width: 100%;
}

.confirm_name {
    color: #838383;
    font-size: 15px;
    padding-top: 10px;
}

.confirm_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    padding: 50px 0;
}

.CardPage {
    position: relative;
    border: 10px solid #eee;
}

.CardPage_img{
    margin-bottom: 9px;
}

.CardPage_img > img {
    display: block;
    width: 95%;
    margin: 0 auto;
}

.CardPage_title {
    color: #838383;
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    text-align: left;
    margin: 6px 0 7px 0;
    font-size: 16px;
    padding: 0 9px;
    word-wrap: break-word;
    &>.file_fix{
        // width: 20%;
    }
    &>.name_file{
        width: 68%;
    }
}

.CardPage_btn {
    color: #ececee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>button{
        width: 50%;
    }
}

.Need_modification {
    color: red;
}

.No_modification_required_bott {
    background-color: #00bc70;
    color: #fff !important;
    border: none;
}

.No_modification_required {
    width: 80%;
    color: #00bc70;
    margin: 0 auto;
}

.triangle_topright {
    position: absolute;
    top: 0;
    right: 0;
    // width: 0;
    // height: 0;
}

// 等待确认
.WaitingConfirmation {
    border-top: 67px solid red;
    border-left: 67px solid transparent; 
}
// 修改中
.updateing {
    border-top: 67px solid #ff9900;
    border-left: 67px solid transparent; 
}
// 已确认
.Confirmed {
    border-top: 67px solid #00bc70;
    border-left: 67px solid transparent; 
}

// 等待修改
.wait_for_revising {
    border-top: 67px solid #d7e273;
    border-left: 67px solid transparent; 
}

//// 首页

// 等待确认
.WaitingConfirmation_index {
    border-top: 50px solid red;
    border-left: 50px solid transparent; 
}
// 修改中
.updateing_index {
    border-top: 50px solid #ff9900;
    border-left: 50px solid transparent; 
}
// 已确认
.Confirmed_index {
    border-top: 50px solid #00bc70;
    border-left: 50px solid transparent; 
}

// 等待修改
.wait_for_revising_index {
    border-top: 50px solid #646464;
    border-left: 50px solid transparent; 
}

.tag_text {
    display: inline-block;
    width: 50px;
    position: absolute;
    font-size: 9px;
    top: -40px;
    right: -7px;
    color: #fff;
    text-align: center;
    transform: rotateZ(45deg);
}

.triangle_text {
    position: absolute;
    top: 17px;
    right: 0;
    color: #fff;
    // font-weight: bold;
    font-size: 12px;
    transform: rotate(45deg);
}

.wait_for_confirm_text {
    top: 16px !important;
    right: -2px !important;
}

.btn_aciton {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px;
}

// #canvas {

// }

.title_text {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: 12px;
}

.canvas {
    // transform: rotateZ(90deg);
    margin: 10px;
    box-shadow: 3px 3px 3px #eee;
}

// body:before {
//     width: 100%;
//     height: 100%;
//     content: ' ';
//     position: fixed;
//     z-index: -1;
//     top: 0;
//     left: 0;
//     background: #fff;
//   }

.action_canvas {
    position: fixed;
    right: 5%;
    bottom: 10%;
    width: 50px;
    height: 50px;
    line-height: 1;
    border-radius: 50%;
    padding: 12px;
    color: #00bc70;
    font-size: 13px;
    text-align: center;
    background: #fff;
    word-break: keep-all;
    box-shadow: 2px 3px 8px #cacaca;
}

.look_content {
    padding: 20px 10px;
    background-color: #fff;
    &>.look_warp_right_close{
        display: flex;
        justify-content: end;
    }
}

.update_info {
    font-size: 13px;
    line-height: 1.5;
}

.Contact_service {
    position: fixed;
    bottom: 10%;
    right: 5%;
    width: 50px;
    height: 50px;
    padding: 11px;
    font-size: 13px;
    text-align: center;
    line-height: 1;
    border-radius: 50%;
    background: #fff;
    box-shadow: 1px 2px 15px #d5d5d5;
    &>a{
        color: #00bc70;
        display: block;
    }
}