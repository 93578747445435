.satisfaction-research {
}
.satisfaction-research .page-header {
  line-height: 3;
  background: white;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
}
.satisfaction-research .paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.satisfaction-research .paper .paper-line {
  line-height: 3;
  font-size: 0.8em;
  letter-spacing: 1px;
  width: 95%;
  border-bottom: 1px solid #a0a0a0;
}
.satisfaction-research .paper .radio {
  font-size: 0.7em;
  color: rgb(79, 79, 79);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.satisfaction-research .suggestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.satisfaction-research .suggestion .title {
  width: 100%;
  font-size: 0.7em;
}
.satisfaction-research .suggestion textarea {
  margin-top: 13px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #6c9bc3;
  min-height: 5em;
  font-size: 0.7em;
  width: 80%;
}

.satisfaction-research .button-row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 15px;
}
.satisfaction-research .button-row button {
  min-width: 100px;
}
