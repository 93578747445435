.Topic_Content {
    padding: 10px;
}

.Topic_Content img {
    width: 100% !important;
}

.Topic_Content > hr {
    margin: 15px 0;
}