.cntent_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 20px;
    background-color: #dbdbdb;
}

.item_images {
    width: 47%;
    margin-bottom: 20px;
    &>img{
        width: 100%;
        border: 5px solid #fff;
    }
}

.title {
    // position: fixed;
    // top: 12%;
    // z-index: 10;
    width: 90%;
    // left: 0;
    // right: 0;
    margin: 0 auto;
    color: #00bc70;
    line-height: 2;
    font-size: 13px;
    font-weight: bold;
    border-radius: 25px;
    // padding: 7px 8px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &>span{
        font-size: 30px;
    }
}

.list{
    // height: 70vh;
    // padding: 80px 0;
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #eee;
    border-top: 1px solid #eee;
    &>.item{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        border-bottom: 1px solid #c5c5c5;
        overflow: hidden;
        &>.left{
            margin-right: 10px;
            &>img{
                height: 80px;
            }
        }
        &>.right{
            padding-right: 33px;
            &>.title_d{
                color: #000;
                font-size: 17px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            &>div{
                color: #818181;
                font-size: 14px;
                line-height: 1.5;
            }
        }
        &>.icon_add{
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 25px;
            color: #fff;
            padding: 3px;
            font-size: 28px;
            border-radius: 7px;
            background-color: #00bc70;
        }
        &>.icon_close{
            position: absolute;
            right: 20px;
            bottom: 40px;
            color: red;
            font-size: 28px;
        }
    }
}

.menu_footer {
    position: relative;
    width: 100%;
    // left: 0;
    // right: 0;
    // margin: 0 auto;
    // bottom: 12%;
    margin: 0 auto;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 25px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    padding-left: 70px;
    line-height: 3;
    background-color: #dbdbdb;
    &>.left_footer{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 30%;
        text-align: center;
        line-height: 3;
        color: #fff;
        font-weight: 300;
        background-color: #00bc706b;
    }
    &>.right_footer{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30%;
        text-align: center;
        line-height: 3;
        color: #fff;
        font-weight: 300;
        background-color: #00bc70;
    }
}

.Popup_Content {
    height: 60vh;
    overflow-y: scroll;
    padding: 10px;
    background-color: #fff;
    padding-top: 50px;
}

.close_icon{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    text-align: right;
    padding: 10px;
    font-size: 15px;
    font-weight: bold;
    z-index: 10;
}


.content_info {
    height: 80vh;
    overflow-y: scroll;
    padding: 10px;
    background-color: #fff;
    &>img{
        margin: 5px 0;
        border-radius: 7px;
    }
    &>.title_c{
        color: #fff;
        padding: 7px;
        border-radius: 7px;
        background: #00bc70;
    }
}

.inter {
    color: rgb(95, 95, 95);
    font-size: 13px;
    padding: 3px 0;
}

.info_list {
    font-size: 14px;
    &>p{
        font-size: 18px;
        font-weight: bold;
    }
    &>div{
        padding: 3px 0;
        color: rgb(121, 121, 121);
        &>span{
            color: #000;
            font-weight: bold;
        }
    }
}