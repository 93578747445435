.customer-clothe-save-modal-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  button {
    width: 45%;
  }
}

.customer-preorder-clothe-popup {
  background: white;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 0.7em;

  .submit-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5px;
    margin-bottom: 5px;

    button {
      width: 25vw;
    }
  }

  .tag-row {
    display: flex;
    padding: 3px 15px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 60px;
      flex-grow: 0;
    }

    .button-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 1;
      align-items: flex-start;
    }

    .button {
      color: black;
      border: #19c286 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      border-radius: 8888px;
      min-width: 60px;
      padding: 0 10px;
      height: 30px;
    }

    .button.active {
      background: #19c286;
      color: white;
    }
  }
}

.customer-preorder-clothe-filter-button {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10em;
  right: 1em;
  background: #00bc71;
  border-radius: 50%;
  padding: 4px;
  font-size: 15px;
  border: 1px solid #00bc71;
  color: #fff;

  &>img {
    width: 100%;
  }
}

$pic_width: 48vw;

.customer-preorder-clothe {
  min-height: 100vh;
  padding-bottom: 250px;
  display: flex;
  justify-content: space-around;
  flex-flow: row;
  background: #eeeeee;

  .first_col {
    width: $pic_width;
    margin-left: (100vw-$pic_width * 2)/3;
    margin-right: (100vw-$pic_width * 2)/3/2;
  }

  .second_col {
    width: $pic_width;
    margin-right: (100vw-$pic_width * 2)/3;
    margin-left: (100vw-$pic_width * 2)/3/2;
  }

  .img-container {
    .tag {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: absolute;
      left: 0;
      top: 13px;
      background: #ffffff7a;
      color: black;
      padding: 0 5px;
      font-size: 10px;
      margin: 0 auto;
      border-bottom-right-radius: 5px;

      &>span {
        font-size: 9px;
      }
    }

    position: relative;

    img {
      margin-top: (100vw-$pic_width * 2)/3;
      max-width: 100%;
      border: 5px solid #fff;
    }

    .button {
      position: absolute;
      top: 0;
      bottom: -0.2em;
      right: 0;
      width: 1.5em;
      height: 1.5em;
      background: white;
      font-size: 1.2em;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #00bc71;
      box-shadow: 1px 1px 6px 0px #bdbdbd;
    }
  }

  .active-photo {
    outline: 2px solid #07be82;
  }
}

.customer-preorder-clothe-button-row {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #00bc71;
  color: white;
  padding: 10px 0;
  line-height: 2;

  .label {
    font-size: 0.8em;
    text-align: center;
    width: 50vw;
  }

  .label {
    border-right: 1px solid #e2e2e2;
  }

  .label:last-child {
    border-right: none;
  }

  .submit {
    color: #e2e2e2;
  }

  .enabled {
    color: #00bc71;
  }
}

.customer-clothe-popup-container {
  position: relative;
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 50px;
  background-color: white;
  // display: flex;
  // flex-direction: row;
  // overflow-x: auto;

  .close_btn {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    color: #fff;
    padding: 15px 0;
    line-height: 1;
    background: #07be82;
  }

  .button {
    width: 4em;
    margin-top: 4px;
    text-align: center;
    margin-bottom: 4px;
    font-size: 0.6em;
    line-height: 1.5;
    color: #07be82;
    border: 1px solid #00bc71;
    border-radius: 999em;
  }

  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    flex-grow: 1;
    padding: 13px;
    max-height: 20vh;
  }
}

.customer_tag {
  color: #00bc71;
  padding: 5px;
  background: #fff;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;

  &>div {
    font-size: 12px;
    border: 1px solid;
    padding: 0 3px;
    border-radius: 4px;
    // font-weight: bold;
    margin: 2px;
    line-height: 1;
  }
}


.customer_clothe_content {
  padding: 10px;

  &>.customer_clothe_content_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid #07be82;
    font-size: 12px;
    padding: 5px;
    color: #19c286;

    &>.customer_clothe_content_top_tag {
      width: 70%;
      line-height: 1.4;
    }

    &>.customer_clothe_content_top_size {
      font-weight: bold;
    }
  }

  &>.customer_clothe_content_img {
    // border-left: 1px solid #07be82;
    // border-right: 1px solid #07be82;
    // border-bottom: 1px solid #07be82;
    border-bottom: 1px solid #d8d8d8;

    &>.customer_clothe_content_tipes {
      color: #07be82;
      text-align: center;
      font-size: 13px;
      padding: 20px 0;
      // text-decoration: underline;

      &>.customer_clothe_btn {
        width: 37%;
        border: 1px solid #07be82;
        padding: 10px 0;
        border-radius: 25px;
        line-height: 1;
        margin: 0 auto;
        font-size: 14px;
      }


      &>.customer_clothe_btn_action {
        color: #fff !important;
        background: #07be82 !important;
      }
    }

    &>.customer_clothe_content_img_content {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      padding: 10px;

      &>.customer_clothe_content_img_list {
        &>img {
          height: 140px;
        }

        &>.customer_clothe_content_img_close_btn {
          text-align: center;

          &>span {
            padding: 3px 10px;
            font-size: 12px;
            line-height: 1;
            border-radius: 25px;
            color: #00bc71;
            border: 1px solid #07be82;
          }
        }
      }
    }
  }
}

.tag_item_new {
  color: #fff;
  background: #3f3f3f;
  padding: 3px 7px;
  border-radius: 4px;
  margin-right: 5px;
}