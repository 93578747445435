.za-tabs__header {
    position: fixed;
    top: 25px;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
}

.page_content .za-cell__icon {
    padding: 8px 0 !important;
}

.page_content .za-cell__title {
    padding: 0 !important;
}

.page_content .za-cell__inner {
    align-items: unset !important;
}

.page_content .box-description {
    color: #838383;
    font-size: 14px;
}

.page_list {
    padding-top: 20px;
}

.page_list .za-message {
    height: 0;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 10;
    min-height: 25px !important;
    background-color: #fdf4ea;
}