.Test_option{
    position: fixed;
    bottom: 5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    padding: 0 5px;
    // height: 70px;
    // background-color: aquamarine;
    &>.Test_option_schedule{
        font-size: 13px;
        font-weight: bold;
        text-align: center;
    }
    &>.Test_option_content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        &>.Test_option_item{
            &>span{
                padding: 0 5px;
                margin: 0 4px;
                border-radius: 8px;
                border: 1px solid #9c9c9c;
            }
            &>.action {
                background: cornflowerblue;
            }
        }
        &>button{
            font-size: 14px;
            padding: 5px;
        }
    }
}

.Test_Content{
    padding: 5px;
    margin-bottom: 100px;
    & p {
        font-size: 14px;
    }
    & img {
        width: 100% !important;
    }
}

.Test_Loading {
    text-align: center;
    line-height: 20;
    font-size: 15px;
    font-weight: bold;
}

.Test_warning {
    position: fixed;
    top: 40%;
    left: 0;
    right: 0;
    width: 130px;
    margin: 0 auto;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 8px;
    line-height: 1.5;
    background-color: #2f2f2f;
}

.Test_error_correction{
    position: fixed;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    bottom: 25%;
    right: 2%;
    text-align: center;
    padding: 5px;
    font-size: 11px;
    border: 1px solid #7a7a7a;
    border-radius: 50%;
}