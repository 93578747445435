.function-selector .share {
  position: fixed;
  top: 0;
  right: 0;
  font-size: 2em;
  background: #6c9bc3;
}
.function-selector {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.function-selector .selector-icon {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  flex-direction: column;
}
.function-selector .selected {
}
.function-selector .selector-icon .iconfont {
  font-size: 2rem;
  color: rgb(19, 34, 122);
}
.function-selector .selector-icon .text {
  font-size: 0.5rem;
  margin-top: 5px;
  color: black !important;
  width: 100%;
  text-align: center;
}
